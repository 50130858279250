import React, { FC, useEffect } from 'react';

import { config } from 'data';
import { businessAccountService } from 'services';
import { useLang, useModal, useMutation, useQueryInvalidate } from 'hooks';
import { Trash } from 'components/icons';
import { Button, Descriptions, Form, Link, Modal, PopconfirmButton } from 'components/ui';
import { Uuid } from 'types/common';
import { ModalBaseProps } from 'types/components';
import { BusinessAccountMember } from 'types/models';

import RolesModal from './RolesModal';

type MemberModalProps = ModalBaseProps & {
  businessAccountId: Uuid;
  member: BusinessAccountMember;
};

const MemberModal: FC<MemberModalProps> = ({
  businessAccountId,
  member,
  open,
  onClose,
}) => {
  const lang = useLang();
  const rolesModal = useModal();
  const queryInvalidate = useQueryInvalidate();

  const removeMemberMutation = useMutation({
    mutationFn: () => businessAccountService.removeMember(businessAccountId, member.id),
    onSuccess: () => queryInvalidate([config.BUSINESS_ACCOUNT_MEMBERS_QUERY_KEY, businessAccountId]),
    successNotification: lang.get('businessAccount.member.modal.removeSuccess', { email: member.email }),
  });

  const handleRemove = async () => {
    await removeMemberMutation.mutateAsync();

    onClose();
  };

  useEffect(() => {
    if (!open) {
      rolesModal.close();
    }
  }, [open, rolesModal]);

  return (
    <Modal
      title={lang.get('businessAccount.member.modal.viewTitle', { email: member.email })}
      caption={lang.get('businessAccount.member.modal.viewCaption')}
      cancelText={lang.get('common.actions.close')}
      okButtonProps={{ hidden: true }}
      extraActions={(
        <PopconfirmButton
          title={lang.get('businessAccount.member.modal.removeTitle')}
          icon={<Trash />}
          danger
          loading={removeMemberMutation.isPending}
          onConfirm={handleRemove}
        >
          {lang.get('common.actions.remove')}
        </PopconfirmButton>
      )}
      open={open}
      onCancel={onClose}
    >

      <Form.ActionsItem label={lang.choice('businessAccount.member.modal.rolesLabel', member.roles.length)}>
        <Button type="default" onClick={rolesModal.open}>
          {lang.get('businessAccount.member.modal.rolesButton')}
        </Button>
      </Form.ActionsItem>

      <Form.Divider />

      <Descriptions
        items={[{
          label: lang.get('common.form.firstName.label'),
          children: member.firstName ?? '-',
        }, {
          label: lang.get('common.form.lastName.label'),
          children: member.lastName ?? '-',
        }, {
          label: lang.get('common.form.email.label'),
          children: <Link href={`mailto:${member.email}`}>{member.email}</Link>,
        }, {
          label: lang.get('common.form.phone.label'),
          children: member.phone
            ? <Link href={`tel:${member.phone}`}>{member.phone}</Link>
            : '-',
        }]}
      />

      <RolesModal
        businessAccountId={businessAccountId}
        member={member}
        open={rolesModal.opened}
        onClose={rolesModal.close}
      />

    </Modal>
  );
};

export default MemberModal;

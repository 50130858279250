import React, { FC } from 'react';

import { ModalBaseProps } from 'types/components';
import { BusinessApplication, BusinessApplicationStatus } from 'types/models';

import EditModal from './EditModal';
import ViewModal from './ViewModal';

type BusinessApplicationModalProps = ModalBaseProps & {
  businessApplication?: BusinessApplication;
};

const BusinessApplicationModal: FC<BusinessApplicationModalProps> = ({
  businessApplication,
  open,
  onClose,
}) => {
  if (!businessApplication || businessApplication.status === BusinessApplicationStatus.PENDING) {
    return (
      <EditModal
        businessApplication={businessApplication}
        open={open}
        onClose={onClose}
      />
    );
  }

  return (
    <ViewModal
      businessApplication={businessApplication}
      open={open}
      onClose={onClose}
    />
  );
};

export default BusinessApplicationModal;

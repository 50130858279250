import React, { FC, useEffect } from 'react';

import { config, yup } from 'data';
import { profileService } from 'services';
import { useForm, useLang, useModal, useMutation, useQuery, useQueryInvalidate } from 'hooks';
import { Button, Form, Input, Modal } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { ProfileUpdateParams } from 'types/services';

import NotificationsModal from './NotificationsModal';
import PasswordChangeModal from './PasswordChangeModal';

type FormValues = ProfileUpdateParams;

const initialValues: FormValues = {
  //
};

const validationSchema = yup.object({
  firstName: yup.string().notRequired().trim().max(config.NAME_MAX_LENGTH),
  lastName: yup.string().notRequired().trim().max(config.NAME_MAX_LENGTH),
  phone: yup.string().notRequired().phone(),
});

const ProfileModal: FC<ModalBaseProps> = ({ open, onClose }) => {
  const form = useForm<FormValues>();
  const lang = useLang();
  const notificationsModal = useModal();
  const passwordChangeModal = useModal();
  const queryInvalidate = useQueryInvalidate();

  const profileQuery = useQuery({
    queryKey: [config.PROFILE_QUERY_KEY],
    queryFn: profileService.getProfile,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const updateProfileMutation = useMutation({
    mutationFn: profileService.updateProfile,
    onSuccess: () => queryInvalidate([config.PROFILE_QUERY_KEY]),
    successNotification: lang.get('profile.modal.success'),
  });

  const handleSubmit = async (values: FormValues) => {
    await updateProfileMutation.mutateAsync(values);

    onClose();

    return false;
  };

  useEffect(() => {
    if (open && profileQuery.data) {
      form.setFieldsValue(profileQuery.data);
    }
  }, [open, form, profileQuery.data]);

  useEffect(() => {
    if (!open) {
      notificationsModal.close();
      passwordChangeModal.close();
    }
  }, [open, notificationsModal, passwordChangeModal]);

  return (
    <Modal
      title={lang.get('profile.modal.title')}
      caption={lang.get('profile.modal.caption')}
      okText={lang.get('common.actions.save')}
      open={open}
      confirmLoading={updateProfileMutation.isPending}
      onOk={form.submit}
      onCancel={onClose}
    >

      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onFinish={handleSubmit}
      >

        <Form.ActionsItem>
          <Button type="default" onClick={passwordChangeModal.open}>
            {lang.get('profile.modal.actions.changePassword')}
          </Button>
          <Button type="default" onClick={notificationsModal.open}>
            {lang.get('profile.modal.actions.manageNotifications')}
          </Button>
        </Form.ActionsItem>

        <Form.Divider />

        <Form.Columns>
          <Form.Item name="firstName" label={lang.get('common.form.firstName.label')}>
            <Input placeholder={lang.get('common.form.firstName.placeholder')} />
          </Form.Item>
          <Form.Item name="lastName" label={lang.get('common.form.lastName.label')}>
            <Input placeholder={lang.get('common.form.lastName.placeholder')} />
          </Form.Item>
        </Form.Columns>
        <Form.Columns>
          <Form.Item label={lang.get('common.form.email.label')}>
            <Input.Email value={profileQuery.data?.email} readOnly />
          </Form.Item>
          <Form.Item name="phone" label={lang.get('common.form.phone.label')}>
            <Input.Phone />
          </Form.Item>
        </Form.Columns>

      </Form>

      <NotificationsModal
        open={notificationsModal.opened}
        onClose={notificationsModal.close}
      />

      <PasswordChangeModal
        open={passwordChangeModal.opened}
        onClose={passwordChangeModal.close}
      />

    </Modal>
  );
};

export default ProfileModal;

import React, { FC, useEffect } from 'react';

import { config } from 'data';
import { formatter } from 'helpers';
import { bundleService } from 'services';
import { useLang, useModal, useMutation, useQueryInvalidate } from 'hooks';
import { BundleStatus } from 'components/layout';
import { Alert, Button, Descriptions, Form, Link, Modal, PopconfirmButton } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { BundleExtended, BundleStatus as EBundleStatus } from 'types/models';

import ApproveModal from './ApproveModal';
import DetailsModal from './DetailsModal';

type ViewBundleModalProps = ModalBaseProps & {
  bundle: BundleExtended;
};

const ViewBundleModal: FC<ViewBundleModalProps> = ({
  bundle,
  open,
  onClose,
}) => {
  const lang = useLang();
  const approveModal = useModal();
  const detailsModal = useModal();
  const queryInvalidate = useQueryInvalidate();

  const invalidateBundleQueries = async () => {
    await queryInvalidate([config.BUNDLES_QUERY_KEY]);
    await queryInvalidate([config.BUNDLE_QUERY_KEY, bundle.id]);
  };

  const rejectBundleMutation = useMutation({
    mutationFn: () => bundleService.rejectBundle(bundle.id),
    onSuccess: invalidateBundleQueries,
    successNotification: lang.get('bundle.modal.rejectSuccess'),
  });

  const handleReject = async () => {
    await rejectBundleMutation.mutateAsync();

    onClose();
  };

  const isBundleContainsDataError = (bundle: BundleExtended) => {
    if (!bundle.errorText) {
      return false;
    }

    try {
      JSON.parse(bundle.errorText);

      return true;
    } catch (error) {
      return false;
    }
  };

  const isBundleContainsTextError = (bundle: BundleExtended) => {
    if (!bundle.errorText) {
      return false;
    }

    return !isBundleContainsDataError(bundle);
  };

  useEffect(() => {
    if (!open) {
      approveModal.close();
      detailsModal.close();
    }
  }, [open, approveModal, detailsModal]);

  const hasDataError = isBundleContainsDataError(bundle);
  const hasTextError = isBundleContainsTextError(bundle);

  const isEditable = bundle.status === EBundleStatus.NEW;
  const hasActions = Boolean(isEditable || hasDataError);

  return (
    <Modal
      title={lang.get('bundle.modal.businessAccountTitle', { name: bundle.client.name })}
      caption={lang.get('bundle.modal.viewCaption')}
      cancelText={lang.get('common.actions.close')}
      okButtonProps={{ hidden: true }}
      open={open}
      onCancel={onClose}
    >

      <Form.ActionsItem hidden={!hasActions}>
        <Button type="default" hidden={!hasDataError} onClick={detailsModal.open}>
          {lang.get('bundle.modal.actions.viewDetails')}
        </Button>
        <Button
          type="primary"
          ghost
          hidden={!isEditable}
          onClick={approveModal.open}
        >
          {lang.get('common.actions.approve')}
        </Button>
        <PopconfirmButton
          title={lang.get('bundle.modal.rejectTitle')}
          danger
          hidden={!isEditable}
          loading={rejectBundleMutation.isPending}
          onConfirm={handleReject}
        >
          {lang.get('common.actions.reject')}
        </PopconfirmButton>
      </Form.ActionsItem>

      <Form.Divider hidden={!hasActions} />

      <Descriptions
        items={[{
          children: <Alert type="error" message={bundle.errorText} />,
          span: 2,
          hidden: !hasTextError,
        }, {
          label: lang.get('bundle.modal.file.label'),
          children: <Link href={bundle.file.bucketUrl}>{bundle.file.originFileName}</Link>,
          span: 2,
        }, {
          label: lang.get('bundle.modal.id.label'),
          children: bundle.id,
        }, {
          label: lang.get('bundle.modal.externalId.label'),
          children: bundle.externalId,
        }, {
          label: lang.get('common.form.creationDate.label'),
          children: formatter.formatDateTime(bundle.createdAt),
        }, {
          label: lang.get('common.form.status.label'),
          children: <BundleStatus status={bundle.status} />,
        }]}
      />

      <ApproveModal
        bundle={bundle}
        open={approveModal.opened}
        onClose={approveModal.close}
        onSubmit={onClose}
      />

      <DetailsModal
        bundle={bundle}
        open={detailsModal.opened}
        onClose={detailsModal.close}
      />

    </Modal>
  );
};

export default ViewBundleModal;

import React, { FC, useEffect, useState } from 'react';

import { config } from 'data';
import { formatter } from 'helpers';
import { useLang, useModal, useTable, useTableQuery } from 'hooks';
import { useProviderQuery, useProvidersQuery } from 'hooks/queries';
import { Warning } from 'components/icons';
import { ActivityStatus, TableView } from 'components/layout';
import { Form, Modal, Select, Table, Tooltip } from 'components/ui';
import { Uuid } from 'types/common';
import { ModalBaseProps, TableColumns } from 'types/components';
import { ActivityStatus as TActivityStatus, ProviderSimple } from 'types/models';

import styles from './styles.module.css';

import SingleModal from './SingleModal';

type TableParams = {
  search?: string;
  status?: TActivityStatus;
};

const initialTableParams: TableParams = {
  //
};

const ProvidersModal: FC<ModalBaseProps> = ({ open, onClose }) => {
  const lang = useLang();
  const modal = useModal();
  const table = useTable<ProviderSimple, TableParams>([config.PROVIDERS_QUERY_KEY], initialTableParams);

  const [providerId, setProviderId] = useState<Uuid>();

  const activeProvidersQuery = useProvidersQuery({ active: true });
  const providerQuery = useProviderQuery(providerId);

  const providersQuery = useProvidersQuery({
    page: table.page,
    search: table.params.search || undefined,
    active: table.params.status ? table.params.status === TActivityStatus.ACTIVE : undefined,
  });

  const handleViewClick = (provider: ProviderSimple) => () => {
    setProviderId(provider.id);

    modal.open();
  };

  useTableQuery(table, providersQuery);

  useEffect(() => {
    if (!open) {
      modal.close();
    }
  }, [open, modal]);

  const columns: TableColumns<ProviderSimple> = [
    {
      className: styles.table__name,
      key: 'name',
      title: lang.get('provider.list.name'),
      render: (_, provider) => <Table.Truncate>{provider.name}</Table.Truncate>,
    }, {
      className: styles.table__date,
      key: 'date',
      title: lang.get('provider.list.date'),
      render: (_, provider) => formatter.formatDateTime(provider.updatedAt ?? provider.createdAt),
    }, {
      className: styles.table__balance,
      key: 'balance',
      title: lang.get('provider.list.balance'),
      render: (_, provider) => {
        if (provider.balance) {
          return (
            <div className={styles.balance}>
              <div className={styles.balance__item}>
                <strong>{formatter.formatCurrency(provider.balance.available, provider.balance.currency)}</strong>
              </div>
              {provider.balance.pending !== null && (
                <div className={styles.balance__item}>
                  <span>{formatter.formatCurrency(provider.balance.pending, provider.balance.currency)}</span>
                  {lang.get('provider.balance.pending')}
                </div>
              )}
              {provider.balance.total !== null && (
                <div className={styles.balance__item}>
                  <span>{formatter.formatCurrency(provider.balance.total, provider.balance.currency)}</span>
                  {lang.get('provider.balance.total')}
                </div>
              )}
            </div>
          );
        }

        return (
          <Tooltip title={lang.get('provider.list.balanceFailed')}>
            <Warning />
          </Tooltip>
        );
      },
    }, {
      key: 'status',
      title: lang.get('provider.list.status'),
      render: (_, provider) => <ActivityStatus active={provider.active} />,
    },
  ];

  return (
    <Modal
      cancelText={lang.get('common.actions.close')}
      okButtonProps={{ hidden: true }}
      width="large"
      open={open}
      onCancel={onClose}
    >

      <TableView
        title={lang.get('provider.list.title')}
        caption={lang.choice('provider.list.caption', activeProvidersQuery.data?.total ?? 0)}
      >

        <TableView.Filters<TableParams>
          initialValues={table.params}
          withSearch
          inline
          onSubmit={table.setParams}
        >
          <Form.Item name="status">
            <Select
              placeholder={lang.get('common.activityStatuses.all')}
              options={Object.values(TActivityStatus).map((status) => ({
                value: status,
                label: lang.get(`common.activityStatuses.${status.toLowerCase()}`),
              }))}
              allowClear
              popupMatchSelectWidth={false}
            />
          </Form.Item>
        </TableView.Filters>

        <Table<ProviderSimple>
          columns={columns}
          dataSource={table.data}
          pagination={table.pagination}
          rowKey={(provider) => provider.id}
          loading={providersQuery.isFetching}
          clickable
          onRow={(provider) => ({ onClick: handleViewClick(provider) })}
          onChange={table.onChange}
        />

      </TableView>

      {providerQuery.data && (
        <SingleModal
          provider={providerQuery.data}
          open={!providerQuery.isFetching && modal.opened}
          onClose={modal.close}
        />
      )}

    </Modal>
  );
};

export default ProvidersModal;

import React, { FC, useEffect } from 'react';

import { config, yup } from 'data';
import { businessApplicationService } from 'services';
import { useForm, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { useBusinessApplicationDetailsQuery } from 'hooks/queries';
import { Form, Input, Modal, TextArea } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { BusinessApplication } from 'types/models';
import { BusinessApplicationUpdateDetailsParams } from 'types/services';

type FormValues = BusinessApplicationUpdateDetailsParams;

const initialValues: FormValues = {
  //
};

const validationSchema = yup.object({
  averageAccountBalance: yup
    .number()
    .notRequired()
    .decimal()
    .min(0)
    .transform((value) => value || 0),
  fallBelowFee: yup.string().notRequired().trim().max(config.STRING_MAX_LENGTH),
  annualMaintenanceFees: yup.string().notRequired().trim().max(config.STRING_MAX_LENGTH),
  accountOpeningFees: yup.string().notRequired().trim().max(config.STRING_MAX_LENGTH),
  notes: yup.string().notRequired().trim().max(config.STRING_MAX_LENGTH),
});

type BusinessApplicationDetailsModalProps = ModalBaseProps & {
  businessApplication: BusinessApplication;
};

const BusinessApplicationDetailsModal: FC<BusinessApplicationDetailsModalProps> = ({
  businessApplication,
  open,
  onClose,
}) => {
  const form = useForm<FormValues>();
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const businessApplicationDetailsQuery = useBusinessApplicationDetailsQuery(businessApplication.id);

  const updateBusinessApplicationDetailsMutation = useMutation({
    mutationFn: (values: FormValues) => businessApplicationService.updateBusinessApplicationDetails(businessApplication.id, values),
    onSuccess: () => queryInvalidate([config.BUSINESS_APPLICATION_DETAILS_QUERY_KEY, businessApplication.id]),
    successNotification: lang.get('businessApplication.detailsModal.success', { name: businessApplication.name }),
  });

  const handleSubmit = async (values: FormValues) => {
    await updateBusinessApplicationDetailsMutation.mutateAsync(values);

    onClose();
  };

  useEffect(() => {
    if (open && businessApplicationDetailsQuery.data) {
      form.setFieldsValue(businessApplicationDetailsQuery.data);
    }
  }, [open, form, businessApplicationDetailsQuery.data]);

  return (
    <Modal
      title={lang.get('businessApplication.detailsModal.title', { name: businessApplication.name })}
      caption={lang.get('businessApplication.detailsModal.caption')}
      okText={lang.get('common.actions.save')}
      width="small"
      open={open}
      confirmLoading={updateBusinessApplicationDetailsMutation.isPending}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onFinish={handleSubmit}
      >
        <Form.Item name="averageAccountBalance" label={lang.get('businessApplication.detailsModal.averageAccountBalance.label')}>
          <Input.Number placeholder={lang.get('businessApplication.detailsModal.averageAccountBalance.placeholder')} />
        </Form.Item>
        <Form.Item name="accountOpeningFees" label={lang.get('businessApplication.detailsModal.accountOpeningFees.label')}>
          <Input placeholder={lang.get('businessApplication.detailsModal.accountOpeningFees.placeholder')} />
        </Form.Item>
        <Form.Item name="fallBelowFee" label={lang.get('businessApplication.detailsModal.fallBelowFee.label')}>
          <Input placeholder={lang.get('businessApplication.detailsModal.fallBelowFee.placeholder')} />
        </Form.Item>
        <Form.Item name="annualMaintenanceFees" label={lang.get('businessApplication.detailsModal.annualMaintenanceFees.label')}>
          <Input placeholder={lang.get('businessApplication.detailsModal.annualMaintenanceFees.placeholder')} />
        </Form.Item>
        <Form.Item name="notes" label={lang.get('common.form.notes.label')}>
          <TextArea placeholder={lang.get('common.form.notes.placeholder')} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BusinessApplicationDetailsModal;

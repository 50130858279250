import React, { FC, useEffect, useState } from 'react';
import map from 'lodash/map';

import { config, yup } from 'data';
import { transactionService } from 'services';
import { useForm, useFormWatch, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { useAllBusinessAccountVirtualAccountsQuery, useBusinessAccountQuery } from 'hooks/queries';
import { Form, Input, Modal, PopconfirmButton, Select, Space, TextArea } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { BusinessAccount, Transaction } from 'types/models';
import { TransactionCreateParams } from 'types/services';

import BusinessAccountBalance from '../BusinessAccountBalance';
import BusinessAccountSelect from '../BusinessAccountSelect';
import VirtualAccountSelect from '../VirtualAccountSelect';

import styles from './styles.module.css';

import Title from './Title';

type FormValues = TransactionCreateParams;

const initialValues: Omit<FormValues, 'amount'> = {
  clientId: '',
  virtualAccountId: '',
  currency: config.DEFAULT_CURRENCY,
};

const validationSchema = yup.object({
  clientId: yup.string().required().uuid(),
  virtualAccountId: yup.string().required().uuid(),
  referenceId: yup.string().notRequired().trim().max(config.STRING_MD_MAX_LENGTH),
  amount: yup.lazy((value) => {
    const schema = yup.number().required().decimal().transform((value) => value || 0);

    return +value >= 0
      ? schema.min(config.TOP_UP_MIN).max(config.TOP_UP_MAX)
      : schema.min(-config.TOP_UP_MAX).max(-config.TOP_UP_MIN);
  }),
  currency: yup.string().required().currency(),
  notes: yup.string().notRequired().trim().max(config.TEXT_MAX_LENGTH),
});

type EditAdjustmentModalProps = ModalBaseProps & {
  businessAccount?: BusinessAccount;
  transaction?: Transaction;
};

const EditAdjustmentModal: FC<EditAdjustmentModalProps> = ({
  businessAccount,
  transaction,
  open,
  onClose,
}) => {
  const form = useForm<FormValues>();
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const currentClientId = useFormWatch('clientId', form);
  const currentVirtualAccountId = useFormWatch('virtualAccountId', form);

  const [selectedBusinessAccount, setSelectedBusinessAccount] = useState<BusinessAccount>();

  const transactionId = transaction?.id ?? '';

  const businessAccountQuery = useBusinessAccountQuery(currentClientId);
  const virtualAccountsQuery = useAllBusinessAccountVirtualAccountsQuery(currentClientId);

  const selectedVirtualAccount = virtualAccountsQuery.data?.find((account) => account.id === currentVirtualAccountId);

  const invalidateTransactionQueries = async () => {
    await queryInvalidate([config.BUSINESS_ACCOUNTS_QUERY_KEY]);
    await queryInvalidate([config.BUSINESS_ACCOUNT_QUERY_KEY, currentClientId]);
    await queryInvalidate([config.TRANSACTIONS_QUERY_KEY]);
  };

  const createTransactionMutation = useMutation({
    mutationFn: transactionService.createTransaction,
    onSuccess: invalidateTransactionQueries,
    successNotification: lang.get('transaction.modal.createSuccess'),
  });

  const updateTransactionMutation = useMutation({
    mutationFn: (values: FormValues) => transactionService.updateTransaction(transactionId, values),
    onSuccess: invalidateTransactionQueries,
    successNotification: lang.get('transaction.modal.updateSuccess'),
  });

  const approveTransactionMutation = useMutation({
    mutationFn: () => transactionService.approveTransaction(transactionId),
    onSuccess: invalidateTransactionQueries,
    successNotification: lang.get('transaction.modal.approveSuccess'),
  });

  const rejectTransactionMutation = useMutation({
    mutationFn: () => transactionService.rejectTransaction(transactionId),
    onSuccess: invalidateTransactionQueries,
    successNotification: lang.get('transaction.modal.rejectSuccess'),
  });

  const handleSubmit = async (values: FormValues) => {
    transaction
      ? await updateTransactionMutation.mutateAsync(values)
      : await createTransactionMutation.mutateAsync(values);

    onClose();
  };

  const handleApprove = async () => {
    await approveTransactionMutation.mutateAsync();

    onClose();
  };

  const handleReject = async () => {
    await rejectTransactionMutation.mutateAsync();

    onClose();
  };

  useEffect(() => {
    if (open) {
      form.setFieldsValueWithoutTouching({
        ...transaction,
        clientId: transaction?.clientId || businessAccount?.id,
        virtualAccountId: transaction?.virtualAccount?.id ?? '',
        amount: transaction?.originAmount,
        currency: transaction?.originCurrency || initialValues.currency,
        referenceId: transaction?.clientReferenceId,
      });

      setSelectedBusinessAccount(businessAccount);
    } else {
      setSelectedBusinessAccount(undefined);
    }
  }, [businessAccount, transaction, open, form]);

  useEffect(() => {
    if (businessAccountQuery.data) {
      setSelectedBusinessAccount(businessAccountQuery.data);

      if (!transaction) {
        form.setFieldsValue({ currency: businessAccountQuery.data.balance?.currency ?? config.DEFAULT_CURRENCY });
      }
    }
  }, [transaction, form, businessAccountQuery.data]);

  useEffect(() => {
    if (form.isFieldTouched('clientId')) {
      form.resetFields(['virtualAccountId']);
    }
  }, [form, currentClientId]);

  useEffect(() => {
    if (form.isFieldTouched('virtualAccountId')) {
      form.setFieldsValue({ currency: selectedVirtualAccount?.balances[0].currency.code ?? config.DEFAULT_CURRENCY });
    }
  }, [form, selectedVirtualAccount]);

  const isEditing = Boolean(transaction);

  return (
    <Modal
      title={<Title businessAccount={selectedBusinessAccount} transaction={transaction} />}
      caption={selectedBusinessAccount && <BusinessAccountBalance businessAccount={selectedBusinessAccount} />}
      okText={lang.get('common.actions.save')}
      open={open}
      confirmLoading={createTransactionMutation.isPending || updateTransactionMutation.isPending}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onFinish={handleSubmit}
      >

        <Form.ActionsItem hidden={!isEditing}>
          <PopconfirmButton
            title={lang.get('transaction.modal.approveTitle')}
            type="primary"
            ghost
            loading={approveTransactionMutation.isPending}
            onConfirm={handleApprove}
          >
            {lang.get('common.actions.approve')}
          </PopconfirmButton>
          <PopconfirmButton
            title={lang.get('transaction.modal.rejectTitle')}
            danger
            loading={rejectTransactionMutation.isPending}
            onConfirm={handleReject}
          >
            {lang.get('common.actions.reject')}
          </PopconfirmButton>
        </Form.ActionsItem>

        <Form.Divider hidden={!isEditing} />

        <Form.Item
          name="clientId"
          label={lang.get('transaction.modal.businessAccount.label')}
          hidden={Boolean(businessAccount || transaction)}
        >
          <BusinessAccountSelect placeholder={lang.get('transaction.modal.businessAccount.placeholder')} />
        </Form.Item>
        <Form.Item name="virtualAccountId" label={lang.get('transaction.modal.account.label')}>
          <VirtualAccountSelect
            businessAccountId={selectedBusinessAccount?.id}
            placeholder={lang.get('transaction.modal.account.placeholder')}
            disabled={!selectedBusinessAccount}
          />
        </Form.Item>
        <Form.Item label={lang.get('transaction.modal.amount.label')}>
          <Space.Compact block>
            <Form.Item name="amount" noStyle>
              <Input.Number
                placeholder={lang.get('transaction.modal.amount.placeholder')}
                disabled={!selectedVirtualAccount}
              />
            </Form.Item>
            <Form.Item name="currency" noStyle>
              <Select.Currency
                className={styles.modal__currency}
                activeCodes={map(selectedVirtualAccount?.balances, 'currency.code')}
                disabled={!selectedVirtualAccount}
                onlyActive
              />
            </Form.Item>
          </Space.Compact>
        </Form.Item>
        <Form.Item name="referenceId" label={lang.get('transaction.modal.referenceId.label')}>
          <Input placeholder={lang.get('transaction.modal.referenceId.placeholder')} />
        </Form.Item>
        <Form.Item name="notes" label={lang.get('common.form.notes.label')}>
          <TextArea placeholder={lang.get('common.form.notes.placeholder')} />
        </Form.Item>

      </Form>
    </Modal>
  );
};

export default EditAdjustmentModal;

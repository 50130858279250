import React, { FC } from 'react';

import { xlsx } from 'helpers';
import { useLang, useModal } from 'hooks';
import { Table as TableIcon } from 'components/icons';
import { Button } from 'components/ui';
import { Override, XlsxHeader, XlsxRow } from 'types/common';
import { ButtonProps, ExportColumns } from 'types/components';

import Modal from './Modal';

type ExportButtonProps<DataType> = Override<Omit<ButtonProps, 'children' | 'onClick'>, {
  component?: FC<ButtonProps>;
  fileName: string;
  columns: ExportColumns<DataType>;
  fetchData: () => Promise<DataType[]>;
  onStart?: VoidFunction;
  onEnd?: VoidFunction;
  onFinish?: VoidFunction;
}>;

const ExportButton = <DataType extends object>({
  component: CustomButton,
  fileName,
  columns,
  icon = <TableIcon />,
  fetchData,
  onStart,
  onEnd,
  onFinish,
  ...props
}: ExportButtonProps<DataType>) => {
  const lang = useLang();
  const modal = useModal();

  const handleExport = async (columns: ExportColumns<DataType>) => {
    try {
      onStart?.();

      const data = await fetchData();

      const headers: XlsxHeader[] = columns.map((column) => column.title);
      const rows: XlsxRow[] = data.map((item) => columns.map((column) => column.render(item)));

      xlsx.exportFile(fileName, headers, rows);

      onEnd?.();
    } finally {
      onFinish?.();
    }
  };

  const Component = CustomButton ?? Button;

  return (
    <>

      <Component icon={icon} onClick={modal.open} {...props}>
        {lang.get('common.export.button')}
      </Component>

      <Modal<DataType>
        columns={columns}
        open={modal.opened}
        onSubmit={handleExport}
        onClose={modal.close}
      />

    </>
  );
};

export default ExportButton;

import React, { FC } from 'react';

import { config, yup } from 'data';
import { userService } from 'services';
import { useForm, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { Form, Input, Modal } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { UserCreateParams } from 'types/services';

type FormValues = UserCreateParams;

const initialValues: FormValues = {
  email: '',
};

const validationSchema = yup.object({
  firstName: yup.string().notRequired().trim().max(config.NAME_MAX_LENGTH),
  lastName: yup.string().notRequired().trim().max(config.NAME_MAX_LENGTH),
  email: yup.string().required().email(),
  phone: yup.string().notRequired().phone(),
});

const CreateUserModal: FC<ModalBaseProps> = ({ open, onClose }) => {
  const form = useForm<FormValues>();
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const createUserMutation = useMutation({
    mutationFn: userService.createUser,
    onSuccess: () => queryInvalidate([config.USERS_QUERY_KEY]),
    successNotification: lang.get('user.modal.createSuccess'),
  });

  const handleSubmit = async (values: FormValues) => {
    await createUserMutation.mutateAsync(values);

    onClose();
  };

  return (
    <Modal
      title={lang.get('user.modal.createTitle')}
      caption={lang.get('user.modal.createCaption')}
      okText={lang.get('common.actions.create')}
      open={open}
      confirmLoading={createUserMutation.isPending}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onFinish={handleSubmit}
      >
        <Form.Columns>
          <Form.Item name="firstName" label={lang.get('common.form.firstName.label')}>
            <Input placeholder={lang.get('common.form.firstName.placeholder')} />
          </Form.Item>
          <Form.Item name="lastName" label={lang.get('common.form.lastName.label')}>
            <Input placeholder={lang.get('common.form.lastName.placeholder')} />
          </Form.Item>
        </Form.Columns>
        <Form.Columns>
          <Form.Item name="email" label={lang.get('common.form.email.label')}>
            <Input.Email />
          </Form.Item>
          <Form.Item name="phone" label={lang.get('common.form.phone.label')}>
            <Input.Phone />
          </Form.Item>
        </Form.Columns>
      </Form>
    </Modal>
  );
};

export default CreateUserModal;

import React, { FC } from 'react';

import { formatter } from 'helpers';
import { useLang } from 'hooks';
import { BusinessApplicationStatus } from 'components/layout';
import { Alert, Descriptions, Link, Modal } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { BusinessApplication } from 'types/models';

import Actions from './Actions';

type ViewBusinessApplicationModalProps = ModalBaseProps & {
  businessApplication: BusinessApplication;
};

const ViewBusinessApplicationModal: FC<ViewBusinessApplicationModalProps> = ({
  businessApplication,
  open,
  onClose,
}) => {
  const lang = useLang();

  return (
    <Modal
      title={lang.get('businessApplication.modal.viewTitle', { name: businessApplication.name })}
      caption={lang.get('businessApplication.modal.viewCaption')}
      cancelText={lang.get('common.actions.close')}
      okButtonProps={{ hidden: true }}
      open={open}
      onCancel={onClose}
    >

      <Actions businessApplication={businessApplication} onSubmit={onClose} />

      <Descriptions
        items={[{
          children: <Alert type="error" message={businessApplication.rejectReason} />,
          span: 2,
          hidden: !businessApplication.rejectReason,
        }, {
          label: lang.get('common.form.name.label'),
          children: businessApplication.name,
        }, {
          label: lang.get('common.form.email.label'),
          children: <Link href={`mailto:${businessApplication.email}`}>{businessApplication.email}</Link>,
        }, {
          label: lang.get('businessApplication.modal.industry.label'),
          children: businessApplication.mainIndustry?.value ?? '-',
        }, {
          label: lang.get('businessApplication.modal.activity.label'),
          children: businessApplication.activity,
        }, {
          label: lang.get('common.form.creationDate.label'),
          children: formatter.formatDateTime(businessApplication.createdAt),
        }, {
          label: lang.get('common.form.status.label'),
          children: <BusinessApplicationStatus status={businessApplication.status} />,
        }]}
      />

    </Modal>
  );
};

export default ViewBusinessApplicationModal;

import React, { useMemo } from 'react';

import { config, moment } from 'data';
import { formatter } from 'helpers';
import { reportService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useLang, useQuery, useTable, useTableQuery } from 'hooks';
import { useAllBasicProvidersQuery } from 'hooks/queries';
import { DashboardLayout } from 'layouts';
import { ExportButton, TableView } from 'components/layout';
import { DateRangePicker, Form, Select, Table } from 'components/ui';
import { Uuid } from 'types/common';
import { ExportColumns, TableColumns } from 'types/components';
import { ReportProvidersDailyBalanceItem } from 'types/models';
import { ReportProvidersDailyBalanceParams } from 'types/services';

import styles from './styles.module.css';

const EXPORT_FILE_NAME = 'report-providers-daily-balance';

type TableParams = {
  providerId?: Uuid;
  dates: [string, string];
};

const initialTableParams: TableParams = {
  dates: [
    moment().subtract(1, 'day').startOf('day').toISOString(),
    moment().subtract(1, 'day').endOf('day').toISOString(),
  ],
};

const ProvidersDailyBalancePage = () => {
  const lang = useLang();
  const table = useTable<ReportProvidersDailyBalanceItem, TableParams>([config.PROVIDERS_DAILY_BALANCE_QUERY_KEY], initialTableParams);

  const providersQuery = useAllBasicProvidersQuery();

  const reportParams: ReportProvidersDailyBalanceParams = {
    page: table.page,
    providerId: table.params.providerId || undefined,
    dateFrom: table.params.dates[0],
    dateTo: table.params.dates[1],
  };

  const reportQuery = useQuery({
    queryKey: [config.PROVIDERS_DAILY_BALANCE_QUERY_KEY, reportParams],
    queryFn: () => reportService.getProvidersDailyBalance(reportParams),
  });

  useTableQuery(table, reportQuery);

  const formatItemDate = (item: ReportProvidersDailyBalanceItem) => formatter.formatDate(item.date);

  const formatItemProvider = (item: ReportProvidersDailyBalanceItem) => item.provider;

  const formatItemCurrency = (item: ReportProvidersDailyBalanceItem) => item.currency;

  const formatItemAvailable = (item: ReportProvidersDailyBalanceItem) => formatter.formatNumber(item.available);

  const formatItemPending = (item: ReportProvidersDailyBalanceItem) => item.pending !== null ? formatter.formatNumber(item.pending) : '-';

  const formatItemTotal = (item: ReportProvidersDailyBalanceItem) => item.total !== null ? formatter.formatNumber(item.total) : '-';

  const exportColumns: ExportColumns<ReportProvidersDailyBalanceItem> = useMemo(() => [
    {
      title: lang.get('provider.dailyBalance.date'),
      render: (item) => formatItemDate(item),
    }, {
      title: lang.get('provider.dailyBalance.provider'),
      render: (item) => formatItemProvider(item),
    }, {
      title: lang.get('provider.dailyBalance.currency'),
      render: (item) => formatItemCurrency(item),
    }, {
      title: lang.get('provider.dailyBalance.available'),
      render: (item) => formatItemAvailable(item),
    }, {
      title: lang.get('provider.dailyBalance.pending'),
      render: (item) => formatItemPending(item),
    }, {
      title: lang.get('provider.dailyBalance.total'),
      render: (item) => formatItemTotal(item),
    },
  ], [lang]);

  const tableColumns: TableColumns<ReportProvidersDailyBalanceItem> = [
    {
      className: styles.table__date,
      key: 'date',
      title: lang.get('provider.dailyBalance.date'),
      render: (_, item) => formatItemDate(item),
    }, {
      key: 'provider',
      title: lang.get('provider.dailyBalance.provider'),
      render: (_, item) => formatItemProvider(item),
    }, {
      key: 'currency',
      title: lang.get('provider.dailyBalance.currency'),
      render: (_, item) => formatItemCurrency(item),
    }, {
      className: styles.table__amount,
      key: 'available',
      title: lang.get('provider.dailyBalance.available'),
      render: (_, item) => formatItemAvailable(item),
    }, {
      className: styles.table__balance,
      key: 'pending',
      title: lang.get('provider.dailyBalance.pending'),
      render: (_, item) => formatItemPending(item),
    }, {
      className: styles.table__balance,
      key: 'total',
      title: lang.get('provider.dailyBalance.total'),
      render: (_, item) => formatItemTotal(item),
    },
  ];

  return (
    <DashboardLayout title={lang.get('provider.dailyBalance.title')}>
      <TableView
        title={lang.get('provider.dailyBalance.title')}
        actions={(
          <ExportButton<ReportProvidersDailyBalanceItem>
            fileName={[
              EXPORT_FILE_NAME,
              moment(table.params.dates[0]).format(config.DATE_RAW_FORMAT),
              moment(table.params.dates[1]).format(config.DATE_RAW_FORMAT),
            ].filter(Boolean).join('-')}
            columns={exportColumns}
            fetchData={() => fetchPaginatedResponseFully(reportService.getProvidersDailyBalance, reportParams)}
            loading={table.exporting}
            disabled={!table.data.length}
            onStart={() => table.setExporting(true)}
            onFinish={() => table.setExporting(false)}
          />
        )}
      >

        <TableView.Filters<TableParams>
          initialValues={table.params}
          inline
          onSubmit={table.setParams}
        >
          <Form.Item name="providerId">
            <Select
              placeholder={lang.get('provider.filters.providers')}
              options={providersQuery.data?.map((provider) => ({
                value: provider.id,
                label: provider.name,
              }))}
              allowClear
              loading={providersQuery.isFetching}
              popupMatchSelectWidth={false}
            />
          </Form.Item>
          <Form.Item name="dates">
            <DateRangePicker maxDate={moment().subtract(1, 'day').endOf('day')} allowClear={false} />
          </Form.Item>
        </TableView.Filters>

        <Table<ReportProvidersDailyBalanceItem>
          columns={tableColumns}
          dataSource={table.data}
          pagination={table.pagination}
          rowKey={(item) => item.provider + item.date + item.currency}
          loading={reportQuery.isFetching}
          onChange={table.onChange}
        />

      </TableView>
    </DashboardLayout>
  );
};

export default ProvidersDailyBalancePage;

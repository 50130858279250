import React, { FC } from 'react';
import cx from 'classnames';

import { TableViewProps } from 'types/components';

import CardView from '../CardView';

import styles from './styles.module.css';

import Filters from './Filters';

const TableView: FC<TableViewProps> = ({ className, ...props }) => (
  <CardView className={cx(styles.root, className)} {...props} />
);

export default Object.assign(TableView, { Filters });

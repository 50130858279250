import React, { FC, useMemo, useState } from 'react';
import truncate from 'lodash/truncate';

import { config, moment } from 'data';
import { formatter } from 'helpers';
import { transactionService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useForm, useFormWatch, useLang, useModal, useTable, useTableQuery } from 'hooks';
import { useAllBasicProvidersQuery, useTransactionsQuery } from 'hooks/queries';
import { TopUp } from 'components/icons';
import { Button, Checkbox, DateRangePicker, Flex, Form, Select, Space, Table } from 'components/ui';
import { ExportColumns, TableColumns, TableViewProps } from 'types/components';
import { TransactionsParams } from 'types/services';
import { TransactionsViewFilters } from 'types/views';

import {
  BusinessAccount,
  Transaction,
  TransactionDirection as ETransactionDirection,
  TransactionStatus as ETransactionStatus,
  TransactionType as ETransactionType,
} from 'types/models';

import BusinessAccountIcon from '../BusinessAccountIcon';
import ExportButton from '../ExportButton';
import TableView from '../TableView';
import TransactionModal from '../TransactionModal';
import TransactionStatus from '../TransactionStatus';

import styles from './styles.module.css';

const EXPORT_FILE_NAME = 'transactions';

type TableParams = TransactionsViewFilters;

const initialTableParams: TableParams = {
  //
};

const typeDirections = transactionService.getTransactionTypeDirections();

type TransactionsViewProps = Pick<TableViewProps, 'icon' | 'title' | 'caption'> & {
  businessAccount?: BusinessAccount;
};

const TransactionsView: FC<TransactionsViewProps> = ({ businessAccount, ...props }) => {
  const filtersForm = useForm<TableParams>();
  const lang = useLang();
  const modal = useModal();
  const table = useTable<Transaction, TableParams>([config.TRANSACTIONS_QUERY_KEY, businessAccount?.id], initialTableParams);

  const currentDirectionFilter = useFormWatch('direction', filtersForm);

  const [transaction, setTransaction] = useState<Transaction>();

  const providersQuery = useAllBasicProvidersQuery();

  const transactionsParams: TransactionsParams = {
    clientId: businessAccount?.id,
    page: table.page,
    search: table.params.search || undefined,
    providerId: table.params.providerId || undefined,
    direction: table.params.direction || undefined,
    originCurrency: table.params.originCurrency || undefined,
    destinationCurrency: table.params.destinationCurrency || undefined,
    status: table.params.status || undefined,
    transactionType: table.params.transactionType || undefined,
    dateFrom: (table.params.dates && table.params.dates[0]) || undefined,
    dateTo: (table.params.dates && table.params.dates[1]) || undefined,
  };

  const transactionsQuery = useTransactionsQuery(transactionsParams);

  const handleCreateClick = () => {
    setTransaction(undefined);

    modal.open();
  };

  const handleViewClick = (transaction: Transaction) => () => {
    setTransaction(transaction);

    modal.open();
  };

  useTableQuery(table, transactionsQuery);

  const getTransactionUpdateDate = (transaction: Transaction) => transaction.statusChangedAt ?? transaction.createdAt;

  const formatTransactionBusinessAccount = (transaction: Transaction) => transaction.client.name;

  const formatTransactionProvider = (transaction: Transaction) => transaction.provider?.name ?? '-';

  const exportColumns: ExportColumns<Transaction> = useMemo(() => [
    {
      title: lang.get('transaction.list.creationDate'),
      render: (transaction) => formatter.formatDateTime(transaction.createdAt),
    }, {
      title: lang.get('transaction.list.updateDate'),
      render: (transaction) => formatter.formatDateTime(getTransactionUpdateDate(transaction)),
    }, {
      title: lang.get('transaction.list.id'),
      render: (transaction) => transaction.id,
    }, {
      title: lang.get('transaction.list.flowId'),
      render: (transaction) => transaction.flowId ?? '-',
    }, {
      title: lang.get('transaction.list.clientReferenceId'),
      render: (transaction) => transaction.clientReferenceId ?? '-',
    }, {
      title: lang.get('transaction.list.accountId'),
      render: (transaction) => transaction.virtualAccount?.id ?? '-',
    }, {
      title: lang.get('transaction.list.accountNumber'),
      render: (transaction) => transaction.virtualAccount?.externalId ?? '-',
    }, {
      title: lang.get('transaction.list.businessAccount'),
      render: (transaction) => formatTransactionBusinessAccount(transaction),
    }, {
      title: lang.get('transaction.list.beneficiary'),
      render: (transaction) => transaction.beneficiary ?? '-',
    }, {
      title: lang.get('transaction.list.provider'),
      render: (transaction) => formatTransactionProvider(transaction),
    }, {
      title: lang.get('transaction.list.incoming'),
      render: (transaction) => formatter.formatNumber(transaction.previousBalance),
    }, {
      title: lang.get('transaction.list.originCurrency'),
      render: (transaction) => transaction.originCurrency,
    }, {
      title: lang.get('transaction.list.originAmount'),
      render: (transaction) => formatter.formatNumber(transaction.originAmount),
    }, {
      title: lang.get('transaction.list.destinationCurrency'),
      render: (transaction) => transaction.destinationCurrency,
    }, {
      title: lang.get('transaction.list.destinationAmount'),
      render: (transaction) => formatter.formatNumber(transaction.destinationAmount),
    }, {
      title: lang.get('transaction.list.fees'),
      render: (transaction) => formatter.formatNumber(transaction.transactionFee),
    }, {
      title: lang.get('transaction.list.outgoing'),
      render: (transaction) => formatter.formatNumber(transaction.balance),
    }, {
      title: lang.get('transaction.list.status'),
      render: (transaction) => lang.get(`transaction.statuses.${transaction.status.toLowerCase()}`),
    },
  ], [lang]);

  const tableColumns: TableColumns<Transaction> = [
    {
      className: styles.table__date,
      key: 'date',
      title: lang.get('transaction.list.updateDate'),
      render: (_, transaction) => (
        <span title={formatter.formatDateTime(getTransactionUpdateDate(transaction))}>
          {formatter.formatDate(getTransactionUpdateDate(transaction))}
        </span>
      ),
    }, {
      className: styles.table__id,
      key: 'id',
      title: lang.get('transaction.list.id'),
      render: (_, transaction) => (
        <span title={transaction.id}>
          {truncate(transaction.id, { length: config.TABLE_ID_MAX_LENGTH })}
        </span>
      ),
    }, {
      key: 'businessAccount',
      title: lang.get('transaction.list.businessAccount'),
      hidden: Boolean(businessAccount),
      render: (_, transaction) => (
        <Table.Truncate title={formatTransactionBusinessAccount(transaction)}>
          <BusinessAccountIcon businessAccountType={transaction.client.type} />
          <span>{formatTransactionBusinessAccount(transaction)}</span>
        </Table.Truncate>
      ),
    }, {
      key: 'provider',
      title: lang.get('transaction.list.provider'),
      render: (_, transaction) => formatTransactionProvider(transaction),
    }, {
      className: styles.table__balance,
      key: 'incoming',
      title: lang.get('transaction.list.incoming'),
      render: (_, transaction) => formatter.formatCurrency(transaction.previousBalance, transaction.originCurrency),
    }, {
      className: styles.table__amount,
      key: 'originAmount',
      title: lang.get('transaction.list.originAmount'),
      render: (_, transaction) => formatter.formatCurrency(transaction.originAmount, transaction.originCurrency),
    }, {
      className: styles.table__amount,
      key: 'destinationAmount',
      title: lang.get('transaction.list.destinationAmount'),
      render: (_, transaction) => formatter.formatCurrency(transaction.destinationAmount, transaction.destinationCurrency),
    }, {
      className: styles.table__balance,
      key: 'fees',
      title: lang.get('transaction.list.fees'),
      render: (_, transaction) => formatter.formatCurrency(transaction.transactionFee, transaction.originCurrency),
    }, {
      className: styles.table__balance,
      key: 'outgoing',
      title: lang.get('transaction.list.outgoing'),
      render: (_, transaction) => formatter.formatCurrency(transaction.balance, transaction.originCurrency),
    }, {
      key: 'status',
      title: lang.get('transaction.list.status'),
      render: (_, transaction) => <TransactionStatus status={transaction.status} />,
    },
  ];

  return (
    <TableView
      actions={(
        <Flex gap="small" wrap="wrap">
          <ExportButton<Transaction>
            type="default"
            fileName={[
              EXPORT_FILE_NAME,
              businessAccount?.name,
              table.params.dates && table.params.dates[0] && moment(table.params.dates[0]).format(config.DATE_RAW_FORMAT),
              table.params.dates && table.params.dates[1] && moment(table.params.dates[1]).format(config.DATE_RAW_FORMAT),
            ].filter(Boolean).join('-')}
            columns={exportColumns}
            fetchData={() => fetchPaginatedResponseFully(transactionService.getTransactions, transactionsParams)}
            loading={table.exporting}
            disabled={!table.data.length}
            onStart={() => table.setExporting(true)}
            onFinish={() => table.setExporting(false)}
          />
          <Button icon={<TopUp />} onClick={handleCreateClick}>
            {lang.get('transaction.list.create')}
          </Button>
        </Flex>
      )}
      {...props}
    >

      <TableView.Filters<TableParams>
        form={filtersForm}
        initialValues={table.params}
        withSearch
        onSubmit={table.setParams}
      >
        <Form.Item name="providerId" label={lang.get('transaction.filters.provider')}>
          <Select
            placeholder={lang.get('common.actions.all')}
            options={providersQuery.data?.map((provider) => ({
              value: provider.id,
              label: provider.name,
            }))}
            allowClear
            loading={providersQuery.isFetching}
          />
        </Form.Item>
        <Form.Item name="direction" label={lang.get('transaction.filters.direction')}>
          <Select
            placeholder={lang.get('common.actions.all')}
            options={Object.values(ETransactionDirection).map((type) => ({
              value: type,
              label: lang.get(`transaction.directions.${type.toLowerCase()}`),
            }))}
            allowClear
          />
        </Form.Item>
        <Form.Item name="originCurrency" label={lang.get('transaction.filters.originCurrency')}>
          <Select.Currency placeholder={lang.get('common.actions.all')} allowClear />
        </Form.Item>
        <Form.Item name="destinationCurrency" label={lang.get('transaction.filters.destinationCurrency')}>
          <Select.Currency placeholder={lang.get('common.actions.all')} allowClear />
        </Form.Item>
        <Form.Divider />
        <Form.Item name="transactionType" label={lang.get('transaction.filters.type')}>
          <Checkbox.Group>
            <Space direction="vertical">
              {Object.values(ETransactionType).map((type) => (
                <Checkbox
                  key={type}
                  value={type}
                  disabled={currentDirectionFilter && !typeDirections[type].includes(currentDirectionFilter)}
                >
                  {lang.get(`transaction.types.${type.toLowerCase()}`)}
                </Checkbox>
              ))}
            </Space>
          </Checkbox.Group>
        </Form.Item>
        <Form.Divider />
        <Form.Item name="status" label={lang.get('transaction.filters.status')}>
          <Checkbox.Group>
            <Space direction="vertical">
              {Object.values(ETransactionStatus).map((status) => (
                <Checkbox key={status} value={status}>
                  {lang.get(`transaction.statuses.${status.toLowerCase()}`)}
                </Checkbox>
              ))}
            </Space>
          </Checkbox.Group>
        </Form.Item>
        <Form.Divider />
        <Form.Item name="dates" label={lang.get('transaction.filters.date')}>
          <DateRangePicker maxDate={moment()} allowEmpty />
        </Form.Item>
      </TableView.Filters>

      <Table<Transaction>
        columns={tableColumns}
        dataSource={table.data}
        pagination={table.pagination}
        rowKey={(transaction) => transaction.id}
        loading={transactionsQuery.isFetching}
        clickable
        onRow={(transaction) => ({ onClick: handleViewClick(transaction) })}
        onChange={table.onChange}
      />

      <TransactionModal
        businessAccount={businessAccount}
        transaction={transaction}
        open={modal.opened}
        onClose={modal.close}
      />

    </TableView>
  );
};

export default TransactionsView;

import { axios } from 'data';
import { Uuid } from 'types/common';
import { BusinessApplication, BusinessApplicationDetails, BusinessApplicationIndustry } from 'types/models';

import {
  BusinessApplicationParams,
  BusinessApplicationRejectParams,
  BusinessApplicationsParams,
  BusinessApplicationsResponse,
  BusinessApplicationUpdateDetailsParams,
} from 'types/services';

import { preparePaginatableParams, preparePaginatedData } from './helpers';

const getBusinessApplications = async (params: BusinessApplicationsParams = {}) => {
  const response = await axios.get<BusinessApplicationsResponse>('/core/admin/business-application', {
    params: preparePaginatableParams(params),
  });

  return preparePaginatedData<BusinessApplication>(response.data);
};

const createBusinessApplication = async (params: BusinessApplicationParams) => {
  await axios.post<never>('/core/admin/business-application', params);
};

const updateBusinessApplication = async (id: Uuid, params: Partial<BusinessApplicationParams>) => {
  await axios.patch<never>(`/core/admin/business-application/${id}/update`, params);
};

const approveBusinessApplication = async (id: Uuid) => {
  await axios.put<never>(`/core/admin/business-application/${id}/verified`);
};

const rejectBusinessApplication = async (id: Uuid, params: BusinessApplicationRejectParams) => {
  await axios.put<never>(`/core/admin/business-application/${id}/unverified`, params);
};

const getBusinessApplicationDetails = async (id: Uuid) => {
  const response = await axios.get<BusinessApplicationDetails>(`/core/admin/business-application/${id}/details`);

  return response.data;
};

const updateBusinessApplicationDetails = async (id: Uuid, params: BusinessApplicationUpdateDetailsParams) => {
  await axios.put<never>(`/core/admin/business-application/${id}/details`, params);
};

const getBusinessApplicationIndustries = async () => {
  const response = await axios.get<BusinessApplicationIndustry[]>('/core/dictionary/industries');

  return response.data;
};

const businessApplicationService = {
  getBusinessApplications,
  createBusinessApplication,
  updateBusinessApplication,
  approveBusinessApplication,
  rejectBusinessApplication,
  getBusinessApplicationDetails,
  updateBusinessApplicationDetails,
  getBusinessApplicationIndustries,
};

export default businessApplicationService;

import { axios } from 'data';
import { Uuid } from 'types/common';
import { Transaction, TransactionDirection, TransactionStatus, TransactionType } from 'types/models';

import {
  TransactionCreateParams,
  TransactionsParams,
  TransactionsResponse,
  TransactionUpdateParams,
} from 'types/services';

import { preparePaginatableParams, preparePaginatedData, prepareTimezonableParams } from './helpers';

const getTransactions = async (params: TransactionsParams = {}) => {
  params = prepareTimezonableParams(params);
  params = preparePaginatableParams(params);

  const response = await axios.get<TransactionsResponse>('/payments/admin/transaction', { params });

  return preparePaginatedData<Transaction>(response.data);
};

const createTransaction = async (params: TransactionCreateParams) => {
  await axios.post<never>('/payments/admin/transaction', params);
};

const updateTransaction = async (id: Uuid, params: TransactionUpdateParams) => {
  await axios.patch<never>(`/payments/admin/transaction/${id}`, params);
};

const approveTransaction = async (id: Uuid) => {
  await updateTransaction(id, { status: TransactionStatus.COMPLETED });
};

const rejectTransaction = async (id: Uuid) => {
  await updateTransaction(id, { status: TransactionStatus.CANCELED });
};

const getTransactionAcceptedStatuses = (): TransactionStatus[] => [
  TransactionStatus.COMPLETED,
];

const getTransactionRejectedStatuses = (): TransactionStatus[] => [
  TransactionStatus.CANCELED,
  TransactionStatus.FAILED,
  TransactionStatus.INSUFFICIENT_FUNDS,
];

const getTransactionTypeDirections = (): Record<TransactionType, TransactionDirection[]> => ({
  [TransactionType.ADJUSTMENT]: [TransactionDirection.INCOMING],
  [TransactionType.EXCHANGE]: [TransactionDirection.INCOMING, TransactionDirection.OUTGOING],
  [TransactionType.EXTERNAL]: [TransactionDirection.OUTGOING],
  [TransactionType.EXTERNAL_CREDIT]: [TransactionDirection.INCOMING],
  [TransactionType.FASTPAY]: [TransactionDirection.OUTGOING],
  [TransactionType.MONTHLY_SERVICE_FEE]: [],
  [TransactionType.P2P]: [TransactionDirection.INCOMING, TransactionDirection.OUTGOING],
  [TransactionType.PAYNOW]: [TransactionDirection.OUTGOING],
  [TransactionType.TT]: [TransactionDirection.OUTGOING],
  [TransactionType.VA_OPEN]: [],
});

const transactionService = {
  getTransactions,
  createTransaction,
  updateTransaction,
  approveTransaction,
  rejectTransaction,
  getTransactionAcceptedStatuses,
  getTransactionRejectedStatuses,
  getTransactionTypeDirections,
};

export default transactionService;

import React, { FC, useEffect } from 'react';

import { config } from 'data';
import { formatter } from 'helpers';
import { useLang, useModal } from 'hooks';
import { Alert, Button, Descriptions, Form, Modal } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { BusinessAccount, Transaction } from 'types/models';

import BusinessAccountBalance from '../BusinessAccountBalance';
import TransactionStatus from '../TransactionStatus';

import DetailsModal from './DetailsModal';
import Title from './Title';

type ViewTransactionModalProps = ModalBaseProps & {
  businessAccount: BusinessAccount;
  transaction: Transaction;
};

const ViewTransactionModal: FC<ViewTransactionModalProps> = ({
  businessAccount,
  transaction,
  open,
  onClose,
}) => {
  const lang = useLang();
  const detailsModal = useModal();

  useEffect(() => {
    if (!open) {
      detailsModal.close();
    }
  }, [open, detailsModal]);

  return (
    <Modal
      title={<Title businessAccount={businessAccount} transaction={transaction} />}
      caption={<BusinessAccountBalance businessAccount={businessAccount} />}
      cancelText={lang.get('common.actions.close')}
      okButtonProps={{ hidden: true }}
      open={open}
      onCancel={onClose}
    >

      <Form.ActionsItem hidden={!transaction.transferDetail}>
        <Button type="default" onClick={detailsModal.open}>
          {lang.get('transaction.modal.actions.viewDetails')}
        </Button>
      </Form.ActionsItem>

      <Form.Divider hidden={!transaction.transferDetail} />

      <Descriptions
        items={[{
          children: <Alert type="error" message={transaction.errorMsg} />,
          span: 2,
          hidden: !transaction.errorMsg,
        }, {
          label: lang.get('transaction.modal.beneficiary.label'),
          children: transaction.beneficiary ?? '-',
          span: 2,
        }, {
          label: lang.get('transaction.modal.accountId.label'),
          children: transaction.virtualAccount?.id ?? '-',
        }, {
          label: lang.get('transaction.modal.accountNumber.label'),
          children: transaction.virtualAccount?.externalId ?? '-',
        }, {
          label: lang.get('transaction.modal.provider.label'),
          children: transaction.provider?.name ?? '-',
        }, {
          label: lang.get('transaction.modal.type.label'),
          children: lang.get(`transaction.types.${transaction.transactionType.toLowerCase()}`),
        }, {
          label: lang.get('common.form.creationDate.label'),
          children: formatter.formatDateTime(transaction.createdAt),
        }, {
          label: lang.get('common.form.status.label'),
          children: <TransactionStatus status={transaction.status} />,
        }]}
      />

      <Form.Divider />

      <Descriptions
        items={[{
          label: lang.get('transaction.modal.originAmount.label'),
          children: formatter.formatCurrency(transaction.originAmount, transaction.originCurrency),
        }, {
          label: lang.get('transaction.modal.destinationAmount.label'),
          children: formatter.formatCurrency(transaction.destinationAmount, transaction.destinationCurrency),
        }, {
          label: lang.get('transaction.modal.currencyRate.label'),
          children: formatter.formatNumber(transaction.currencyRate, config.CURRENCY_RATE_PRECISION),
        }, {
          label: lang.get('transaction.modal.effectiveRate.label'),
          children: formatter.formatNumber(transaction.effectiveRate || transaction.currencyRate, config.CURRENCY_RATE_PRECISION),
        }]}
      />

      <Form.Divider />

      <Descriptions
        items={[{
          label: lang.get('transaction.modal.transactionFee.label'),
          children: formatter.formatCurrency(transaction.transactionFee, transaction.originCurrency),
        }, {
          label: lang.get('transaction.modal.fxMarkup.label'),
          children: formatter.formatCurrency(transaction.fxMarkupFee, transaction.originCurrency),
        }, {
          label: lang.get('transaction.modal.providerFee.label'),
          children: formatter.formatCurrency(transaction.providerFee, transaction.originCurrency),
        }]}
      />

      <Form.Divider />

      <Descriptions
        items={[{
          label: lang.get('transaction.modal.id.label'),
          children: transaction.id,
        }, {
          label: lang.get('transaction.modal.flowId.label'),
          children: transaction.flowId ?? '-',
        }, {
          label: lang.get('transaction.modal.externalId.label'),
          children: transaction.externalId ?? '-',
        }, {
          label: lang.get('transaction.modal.providerReferenceId.label'),
          children: transaction.providerReferenceId ?? '-',
        }, {
          label: lang.get('transaction.modal.clientReferenceId.label'),
          children: transaction.clientReferenceId ?? '-',
          span: 2,
        }, {
          label: lang.get('common.form.notes.label'),
          children: transaction.notes ?? '-',
        }]}
      />

      <DetailsModal
        transaction={transaction}
        open={detailsModal.opened}
        onClose={detailsModal.close}
      />

    </Modal>
  );
};

export default ViewTransactionModal;

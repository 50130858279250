import { axios } from 'data';

import {
  ReportDailyBalanceItem,
  ReportDailyRemittanceItem,
  ReportDailyTransactionItem,
  ReportProvidersDailyBalanceItem,
} from 'types/models';

import {
  ReportDailyBalanceParams,
  ReportDailyBalanceResponse,
  ReportDailyRemittanceParams,
  ReportDailyRemittanceResponse,
  ReportDailyTransactionsParams,
  ReportDailyTransactionsResponse,
  ReportProvidersDailyBalanceParams,
  ReportProvidersDailyBalanceResponse,
} from 'types/services';

import { preparePaginatableParams, preparePaginatedData, prepareTimezonableParams } from './helpers';

const getDailyBalance = async (params: ReportDailyBalanceParams) => {
  params = prepareTimezonableParams(params);
  params = preparePaginatableParams(params);

  const response = await axios.get<ReportDailyBalanceResponse>('/payments/admin/transaction/report/daily', { params });

  return preparePaginatedData<ReportDailyBalanceItem>(response.data);
};

const getDailyRemittance = async (params: ReportDailyRemittanceParams) => {
  const endpoint = params.clientIds ? '/payments/admin/report/client' : '/payments/admin/report';

  params = prepareTimezonableParams(params);
  params = preparePaginatableParams(params);

  const response = await axios.get<ReportDailyRemittanceResponse>(endpoint, { params });

  return preparePaginatedData<ReportDailyRemittanceItem>(response.data);
};

const getDailyTransactions = async (params: ReportDailyTransactionsParams) => {
  params = prepareTimezonableParams(params);
  params = preparePaginatableParams(params);

  const response = await axios.get<ReportDailyTransactionsResponse>('/payments/admin/transaction/report', { params });

  return preparePaginatedData<ReportDailyTransactionItem>(response.data);
};

const getProvidersDailyBalance = async (params: ReportProvidersDailyBalanceParams) => {
  params = prepareTimezonableParams(params);
  params = preparePaginatableParams(params);

  const response = await axios.get<ReportProvidersDailyBalanceResponse>('/payments/admin/provider/report', { params });

  return preparePaginatedData<ReportProvidersDailyBalanceItem>(response.data);
};

const reportService = {
  getDailyBalance,
  getDailyRemittance,
  getDailyTransactions,
  getProvidersDailyBalance,
};

export default reportService;

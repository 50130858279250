import React, { FC, useEffect } from 'react';
import map from 'lodash/map';

import { config, yup } from 'data';
import { businessAccountService } from 'services';
import { useForm, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { Form, Modal } from 'components/ui';
import { Uuid } from 'types/common';
import { ModalBaseProps } from 'types/components';
import { BusinessAccountMember, BusinessAccountRoleId } from 'types/models';
import { BusinessAccountUpdateMemberRolesParams } from 'types/services';

import RolesSelect from './RolesSelect';

type FormValues = BusinessAccountUpdateMemberRolesParams;

const roleIds = Object.values(BusinessAccountRoleId);

const initialValues: FormValues = {
  roleIds: [],
};

const validationSchema = yup.object({
  roleIds: yup.array().required().min(1).of(
    yup.string().required().oneOf(roleIds),
  ),
});

type RolesModalProps = ModalBaseProps & {
  businessAccountId: Uuid;
  member: BusinessAccountMember;
};

const RolesModal: FC<RolesModalProps> = ({
  businessAccountId,
  member,
  open,
  onClose,
}) => {
  const form = useForm<FormValues>();
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const updateMemberRolesMutation = useMutation({
    mutationFn: (values: FormValues) => businessAccountService.updateMemberRoles(businessAccountId, member.id, values),
    onSuccess: () => queryInvalidate([config.BUSINESS_ACCOUNT_MEMBERS_QUERY_KEY, businessAccountId]),
    successNotification: lang.get('businessAccount.member.rolesModal.success', { email: member.email }),
  });

  const handleSubmit = async (values: FormValues) => {
    await updateMemberRolesMutation.mutateAsync(values);

    onClose();
  };

  useEffect(() => {
    if (open && member) {
      form.setFieldsValue({ roleIds: map(member.roles, 'id') });
    }
  }, [member, open, form]);

  return (
    <Modal
      title={lang.get('businessAccount.member.rolesModal.title', { email: member.email })}
      caption={lang.get('businessAccount.member.rolesModal.caption')}
      okText={lang.get('common.actions.save')}
      width="small"
      open={open}
      confirmLoading={updateMemberRolesMutation.isPending}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onFinish={handleSubmit}
      >
        <Form.Item name="roleIds" label={lang.get('businessAccount.member.modal.roles.label')}>
          <RolesSelect />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RolesModal;

import { useCallback, useMemo } from 'react';
import { Form, FormInstance } from 'antd';
import { FieldData } from 'rc-field-form/es/interface';

const useForm = <Values extends object>(instance?: FormInstance<Values>) => {
  const [form] = Form.useForm<Values>(instance);

  const setFieldsValueWithoutTouching = useCallback((values: Partial<Values>) => {
    const fields = Object.entries(values).map<FieldData>(([name, value]) => ({
      name,
      value,
      touched: false,
    }));

    form.setFields(fields);
  }, [form]);

  const reset = useCallback(() => {
    const values = form.getFieldsValue(true);
    const entries = Object.entries(values).map(([name]) => [name, undefined]);

    setFieldsValueWithoutTouching(Object.fromEntries(entries));
  }, [form, setFieldsValueWithoutTouching]);

  return useMemo(() => ({
    ...form,
    setFieldsValueWithoutTouching,
    reset,
  }), [form, setFieldsValueWithoutTouching, reset]);
};

export default useForm;

import React, { FC } from 'react';

import { config } from 'data';
import { businessApplicationService } from 'services';
import { useLang, useModal, useMutation, useQueryInvalidate } from 'hooks';
import { Button, Form, PopconfirmButton } from 'components/ui';
import { BusinessApplication, BusinessApplicationStatus as EBusinessApplicationStatus } from 'types/models';

import DetailsModal from './DetailsModal';
import RejectModal from './RejectModal';

enum BusinessApplicationAction {
  APPROVE = 'approve',
  REJECT = 'reject',
}

const statusActions: Record<EBusinessApplicationStatus, BusinessApplicationAction[]> = {
  [EBusinessApplicationStatus.APPROVED]: [],
  [EBusinessApplicationStatus.ARCHIVED]: [BusinessApplicationAction.APPROVE],
  [EBusinessApplicationStatus.DECLINED]: [BusinessApplicationAction.APPROVE],
  [EBusinessApplicationStatus.PENDING]: [BusinessApplicationAction.APPROVE, BusinessApplicationAction.REJECT],
};

type BusinessApplicationActionsProps = {
  businessApplication: BusinessApplication;
  onSubmit: VoidFunction;
};

const BusinessApplicationActions: FC<BusinessApplicationActionsProps> = ({ businessApplication, onSubmit }) => {
  const lang = useLang();
  const detailsModal = useModal();
  const rejectModal = useModal();
  const queryInvalidate = useQueryInvalidate();

  const approveBusinessApplicationMutation = useMutation({
    mutationFn: () => businessApplicationService.approveBusinessApplication(businessApplication.id),
    onSuccess: () => queryInvalidate([config.BUSINESS_APPLICATIONS_QUERY_KEY]),
    successNotification: lang.get('businessApplication.modal.approveSuccess', { name: businessApplication.name }),
  });

  const handleApprove = async () => {
    await approveBusinessApplicationMutation.mutateAsync();

    onSubmit();
  };

  const hasActions = Boolean(statusActions[businessApplication.status].length);

  return (
    <>

      <Form.ActionsItem hidden={!hasActions}>

        {statusActions[businessApplication.status].includes(BusinessApplicationAction.APPROVE) && (
          <PopconfirmButton
            title={lang.get('businessApplication.modal.approveTitle')}
            type="primary"
            ghost
            loading={approveBusinessApplicationMutation.isPending}
            onConfirm={handleApprove}
          >
            {lang.get('common.actions.approve')}
          </PopconfirmButton>
        )}

        {statusActions[businessApplication.status].includes(BusinessApplicationAction.REJECT) && (
          <Button type="default" danger onClick={rejectModal.open}>
            {lang.get('common.actions.reject')}
          </Button>
        )}

      </Form.ActionsItem>

      <Form.ActionsItem label={lang.get('businessApplication.modal.details')}>
        <Button type="default" onClick={detailsModal.open}>
          {lang.get('common.actions.manage')}
        </Button>
      </Form.ActionsItem>

      <Form.Divider />

      <DetailsModal
        businessApplication={businessApplication}
        open={detailsModal.opened}
        onClose={detailsModal.close}
      />

      <RejectModal
        businessApplication={businessApplication}
        open={rejectModal.opened}
        onClose={rejectModal.close}
        onSubmit={onSubmit}
      />

    </>
  );
};

export default BusinessApplicationActions;

import React, { useCallback, useMemo, useState } from 'react';

import { config } from 'data';
import { formatter } from 'helpers';
import { feeCommissionService, transactionService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useForm, useFormWatch, useLang, useModal, useTable, useTableQuery } from 'hooks';
import { useFeeCommissionsQuery } from 'hooks/queries';
import { Plus } from 'components/icons';
import { ActivityStatus, ExportButton, TableView } from 'components/layout';
import { Button, Flex, Form, Select, Table } from 'components/ui';
import { ExportColumns, TableColumns } from 'types/components';
import { FeeCommissionsParams } from 'types/services';

import {
  FeeCommission,
  FeeCommissionLevel,
  TransactionDirection,
  TransactionFeeType,
  TransactionType,
} from 'types/models';

import styles from './styles.module.css';

import Modal from './Modal';

const EXPORT_FILE_NAME = 'fee-configurations';

type TableParams = {
  level?: FeeCommissionLevel;
  direction?: TransactionDirection;
  transactionType?: TransactionType;
};

const initialTableParams: TableParams = {
  //
};

const transactionTypeDirections = transactionService.getTransactionTypeDirections();

const FeeCommissionsPage = () => {
  const filtersForm = useForm<TableParams>();
  const lang = useLang();
  const modal = useModal();
  const table = useTable<FeeCommission, TableParams>([config.FEE_COMMISSIONS_QUERY_KEY], initialTableParams);

  const currentDirectionFilter = useFormWatch('direction', filtersForm);

  const [feeCommission, setFeeCommission] = useState<FeeCommission>();

  const feeCommissionsParams: FeeCommissionsParams = {
    page: table.page,
    level: table.params.level || undefined,
    transactionType: table.params.transactionType || undefined,
    direction: table.params.direction || undefined,
  };

  const feeCommissionsQuery = useFeeCommissionsQuery(feeCommissionsParams);

  const handleCreateClick = () => {
    setFeeCommission(undefined);

    modal.open();
  };

  const handleViewClick = (feeCommission: FeeCommission) => () => {
    setFeeCommission(feeCommission);

    modal.open();
  };

  useTableQuery(table, feeCommissionsQuery);

  const formatFeeCommissionName = (feeCommission: FeeCommission) => feeCommission.name;

  const formatFeeCommissionLevel = useCallback((feeCommission: FeeCommission) => lang.get(`feeCommission.levels.${feeCommission.level.toLowerCase()}`), [lang]);

  const formatFeeCommissionCurrency = (feeCommission: FeeCommission) => feeCommission.currency;

  const formatFeeCommissionTransactionType = useCallback((feeCommission: FeeCommission) => lang.get(`transaction.types.${feeCommission.transactionType.toLowerCase()}`), [lang]);

  const formatFeeCommissionDirection = useCallback((feeCommission: FeeCommission) => {
    return feeCommission.direction
      ? lang.get(`transaction.directions.${feeCommission.direction.toLowerCase()}`)
      : '-';
  }, [lang]);

  const formatFeeCommissionTransactionFee = (feeCommission: FeeCommission) => {
    if (feeCommission.transactionFeeType === TransactionFeeType.PERCENT) {
      return formatter.formatPercent(feeCommission.transactionFee);
    }

    return formatter.formatCurrency(feeCommission.transactionFee, feeCommission.currency);
  };

  const formatFeeCommissionFxMarkup = (feeCommission: FeeCommission) => formatter.formatPercent(feeCommission.fxMarkup);

  const formatFeeCommissionMinTransactionFeeAmount = useCallback((feeCommission: FeeCommission) => {
    if (feeCommission.transactionFeeType === TransactionFeeType.PERCENT) {
      return formatter.formatCurrency(feeCommission.minTransactionFeeAmount, feeCommission.currency);
    }

    return formatFeeCommissionTransactionFee(feeCommission);
  }, []);

  const formatFeeCommissionMaxTransactionFeeAmount = useCallback((feeCommission: FeeCommission) => {
    if (feeCommission.transactionFeeType === TransactionFeeType.PERCENT) {
      return formatter.formatCurrency(feeCommission.maxTransactionFeeAmount, feeCommission.currency);
    }

    return formatFeeCommissionTransactionFee(feeCommission);
  }, []);

  const exportColumns: ExportColumns<FeeCommission> = useMemo(() => [
    {
      title: lang.get('feeCommission.list.name'),
      render: (feeCommission) => formatFeeCommissionName(feeCommission),
    }, {
      title: lang.get('feeCommission.list.level'),
      render: (feeCommission) => formatFeeCommissionLevel(feeCommission),
    }, {
      title: lang.get('feeCommission.list.transactionType'),
      render: (feeCommission) => formatFeeCommissionTransactionType(feeCommission),
    }, {
      title: lang.get('feeCommission.list.direction'),
      render: (feeCommission) => formatFeeCommissionDirection(feeCommission),
    }, {
      title: lang.get('feeCommission.list.currency'),
      render: (feeCommission) => formatFeeCommissionCurrency(feeCommission),
    }, {
      title: lang.get('feeCommission.list.transactionFee'),
      render: (feeCommission) => formatFeeCommissionTransactionFee(feeCommission),
    }, {
      title: lang.get('feeCommission.list.fxMarkup'),
      render: (feeCommission) => formatFeeCommissionFxMarkup(feeCommission),
    }, {
      title: lang.get('feeCommission.list.minTransactionFeeAmount'),
      render: (feeCommission) => formatFeeCommissionMinTransactionFeeAmount(feeCommission),
    }, {
      title: lang.get('feeCommission.list.maxTransactionFeeAmount'),
      render: (feeCommission) => formatFeeCommissionMaxTransactionFeeAmount(feeCommission),
    }, {
      title: lang.get('feeCommission.list.startDate'),
      render: (feeCommission) => feeCommission.startDate ? formatter.formatDate(feeCommission.startDate) : '-',
    }, {
      title: lang.get('feeCommission.list.endDate'),
      render: (feeCommission) => feeCommission.endDate ? formatter.formatDate(feeCommission.endDate) : '-',
    }, {
      title: lang.get('feeCommission.list.status'),
      render: (feeCommission) => lang.get(feeCommission.active ? 'common.activityStatuses.active' : 'common.activityStatuses.inactive'),
    },
  ], [
    lang,
    formatFeeCommissionLevel,
    formatFeeCommissionTransactionType,
    formatFeeCommissionDirection,
    formatFeeCommissionMinTransactionFeeAmount,
    formatFeeCommissionMaxTransactionFeeAmount,
  ]);

  const tableColumns: TableColumns<FeeCommission> = [
    {
      className: styles.table__name,
      key: 'name',
      title: lang.get('feeCommission.list.name'),
      render: (_, feeCommission) => <Table.Truncate>{formatFeeCommissionName(feeCommission)}</Table.Truncate>,
    }, {
      key: 'level',
      title: lang.get('feeCommission.list.level'),
      render: (_, feeCommission) => formatFeeCommissionLevel(feeCommission),
    }, {
      key: 'transactionType',
      title: lang.get('feeCommission.list.transactionType'),
      render: (_, feeCommission) => formatFeeCommissionTransactionType(feeCommission),
    }, {
      key: 'direction',
      title: lang.get('feeCommission.list.direction'),
      render: (_, feeCommission) => formatFeeCommissionDirection(feeCommission),
    }, {
      key: 'currency',
      title: lang.get('feeCommission.list.currency'),
      render: (_, feeCommission) => formatFeeCommissionCurrency(feeCommission),
    }, {
      className: styles.table__amount,
      key: 'transactionFee',
      title: lang.get('feeCommission.list.transactionFee'),
      render: (_, feeCommission) => formatFeeCommissionTransactionFee(feeCommission),
    }, {
      className: styles.table__amount,
      key: 'fxMarkup',
      title: lang.get('feeCommission.list.fxMarkup'),
      render: (_, feeCommission) => formatFeeCommissionFxMarkup(feeCommission),
    }, {
      className: styles.table__amount,
      key: 'minTransactionFeeAmount',
      title: lang.get('feeCommission.list.minTransactionFeeAmount'),
      render: (_, feeCommission) => formatFeeCommissionMinTransactionFeeAmount(feeCommission),
    }, {
      className: styles.table__amount,
      key: 'maxTransactionFeeAmount',
      title: lang.get('feeCommission.list.maxTransactionFeeAmount'),
      render: (_, feeCommission) => formatFeeCommissionMaxTransactionFeeAmount(feeCommission),
    }, {
      key: 'status',
      title: lang.get('feeCommission.list.status'),
      render: (_, feeCommission) => <ActivityStatus active={feeCommission.active} />,
    },
  ];

  return (
    <TableView
      title={lang.get('feeCommission.list.title')}
      actions={(
        <Flex gap="small" wrap="wrap">
          <ExportButton<FeeCommission>
            type="default"
            fileName={EXPORT_FILE_NAME}
            columns={exportColumns}
            fetchData={() => fetchPaginatedResponseFully(feeCommissionService.getFeeCommissions, feeCommissionsParams)}
            loading={table.exporting}
            disabled={!table.data.length}
            onStart={() => table.setExporting(true)}
            onFinish={() => table.setExporting(false)}
          />
          <Button icon={<Plus />} onClick={handleCreateClick}>
            {lang.get('feeCommission.list.create')}
          </Button>
        </Flex>
      )}
    >

      <TableView.Filters<TableParams>
        form={filtersForm}
        initialValues={table.params}
        onSubmit={table.setParams}
      >
        <Form.Item name="level" label={lang.get('feeCommission.filters.level')}>
          <Select
            placeholder={lang.get('common.actions.all')}
            options={Object.values(FeeCommissionLevel).map((level) => ({
              value: level,
              label: lang.get(`feeCommission.levels.${level.toLowerCase()}`),
            }))}
            allowClear
          />
        </Form.Item>
        <Form.Item name="direction" label={lang.get('feeCommission.filters.direction')}>
          <Select
            placeholder={lang.get('common.actions.all')}
            options={Object.values(TransactionDirection).map((direction) => ({
              value: direction,
              label: lang.get(`transaction.directions.${direction.toLowerCase()}`),
            }))}
            allowClear
          />
        </Form.Item>
        <Form.Item name="transactionType" label={lang.get('feeCommission.filters.transactionType')}>
          <Select
            placeholder={lang.get('common.actions.all')}
            options={Object.values(TransactionType).map((type) => ({
              value: type,
              label: lang.get(`transaction.types.${type.toLowerCase()}`),
              disabled: Boolean(currentDirectionFilter && !transactionTypeDirections[type].includes(currentDirectionFilter)),
            }))}
            allowClear
          />
        </Form.Item>
      </TableView.Filters>

      <Table<FeeCommission>
        columns={tableColumns}
        dataSource={table.data}
        pagination={table.pagination}
        rowKey={(feeCommission) => feeCommission.id}
        loading={feeCommissionsQuery.isFetching}
        clickable
        onRow={(feeCommission) => ({ onClick: handleViewClick(feeCommission) })}
        onChange={table.onChange}
      />

      <Modal
        feeCommission={feeCommission}
        open={modal.opened}
        onClose={modal.close}
      />

    </TableView>
  );
};

export default FeeCommissionsPage;

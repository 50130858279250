import React, { FC, useEffect } from 'react';

import { config, yup } from 'data';
import { file } from 'helpers';
import { providerService } from 'services';
import { useForm, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { Form, Input, Modal, PopconfirmButton, Select, Space, TextArea } from 'components/ui';
import { Nullable } from 'types/common';
import { ModalBaseProps, UploadFileLike } from 'types/components';
import { Provider, ProviderFrequency } from 'types/models';
import { ProviderParams } from 'types/services';

import styles from './styles.module.css';

type FormValues = ProviderParams & {
  uploadFiles?: Nullable<UploadFileLike[]>;
};

const frequencies = Object.values(ProviderFrequency);

const initialValues: Omit<FormValues, 'frequency' | 'minFeeAmount'> = {
  currency: config.DEFAULT_CURRENCY,
};

const validationSchema = yup.object({
  frequency: yup.string().required().oneOf(frequencies),
  minFeeAmount: yup
    .number()
    .required()
    .decimal()
    .min(config.PROVIDER_FEE_MIN)
    .max(config.PROVIDER_FEE_MAX)
    .transform((value) => value || 0),
  currency: yup.string().required().currency(),
  uploadFiles: yup.array().notRequired().of(
    yup.mixed<UploadFileLike>().required().file(),
  ),
  notes: yup.string().notRequired().trim().max(config.TEXT_MAX_LENGTH),
});

type ProviderModalProps = ModalBaseProps & {
  provider: Provider;
};

const ProviderModal: FC<ProviderModalProps> = ({
  provider,
  open,
  onClose,
}) => {
  const form = useForm<FormValues>();
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const providerId = provider.id;
  const providerName = provider.name;

  const invalidateProviderQueries = async () => {
    await queryInvalidate([config.PROVIDERS_QUERY_KEY]);
    await queryInvalidate([config.PROVIDER_QUERY_KEY, providerId]);
  };

  const updateProviderMutation = useMutation({
    mutationFn: async (values: FormValues) => {
      values.files = await file.handleFilesUpload(values.uploadFiles ?? []);

      delete values.uploadFiles;

      return providerService.updateProvider(providerId, values);
    },
    onSuccess: invalidateProviderQueries,
    successNotification: lang.get('provider.modal.updateSuccess', { name: providerName }),
  });

  const activateProviderMutation = useMutation({
    mutationFn: () => providerService.activateProvider(providerId),
    onSuccess: invalidateProviderQueries,
    successNotification: lang.get('provider.modal.activateSuccess', { name: providerName }),
  });

  const deactivateProviderMutation = useMutation({
    mutationFn: () => providerService.deactivateProvider(providerId),
    onSuccess: invalidateProviderQueries,
    successNotification: lang.get('provider.modal.deactivateSuccess', { name: providerName }),
  });

  const handleSubmit = async (values: FormValues) => {
    await updateProviderMutation.mutateAsync(values);

    onClose();
  };

  const handleActivate = async () => {
    await activateProviderMutation.mutateAsync();

    onClose();
  };

  const handleDeactivate = async () => {
    await deactivateProviderMutation.mutateAsync();

    onClose();
  };

  useEffect(() => {
    if (open && provider) {
      form.setFieldsValue({
        ...provider,
        files: [],
        uploadFiles: provider.files.map(file.mapUploadedFileToUploadFile),
      });
    }
  }, [provider, open, form]);

  return (
    <Modal
      title={lang.get('provider.modal.updateTitle', { name: providerName })}
      caption={lang.get('provider.modal.updateCaption')}
      okText={lang.get('common.actions.save')}
      width="small"
      open={open}
      confirmLoading={updateProviderMutation.isPending}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onFinish={handleSubmit}
      >

        <Form.ActionsItem>
          {provider.active ? (
            <PopconfirmButton
              title={lang.get('provider.modal.deactivateTitle')}
              danger
              loading={deactivateProviderMutation.isPending}
              onConfirm={handleDeactivate}
            >
              {lang.get('common.actions.deactivate')}
            </PopconfirmButton>
          ) : (
            <PopconfirmButton
              title={lang.get('provider.modal.activateTitle')}
              type="primary"
              ghost
              loading={activateProviderMutation.isPending}
              onConfirm={handleActivate}
            >
              {lang.get('common.actions.activate')}
            </PopconfirmButton>
          )}
        </Form.ActionsItem>

        <Form.Divider />

        <Form.Item name="name" label={lang.get('common.form.name.label')}>
          <Input readOnly />
        </Form.Item>
        <Form.Item name="frequency" label={lang.get('provider.modal.frequency.label')}>
          <Select
            placeholder={lang.get('provider.modal.frequency.placeholder')}
            options={frequencies.map((frequency) => ({
              value: frequency,
              label: lang.get(`provider.frequencies.${frequency.toLowerCase()}`),
            }))}
          />
        </Form.Item>
        <Form.Item label={lang.get('provider.modal.minFeeAmount.label')}>
          <Space.Compact block>
            <Form.Item name="minFeeAmount" noStyle>
              <Input.Number placeholder={lang.get('provider.modal.minFeeAmount.placeholder')} />
            </Form.Item>
            <Form.Item name="currency" noStyle>
              <Select.Currency className={styles.modal__currency} disabled />
            </Form.Item>
          </Space.Compact>
        </Form.Item>
        <Form.UploadItem name="uploadFiles" label={lang.get('provider.modal.files.label')}>
          <Input.Upload maxCount={0} />
        </Form.UploadItem>
        <Form.Item name="notes" label={lang.get('common.form.notes.label')}>
          <TextArea placeholder={lang.get('common.form.notes.placeholder')} />
        </Form.Item>

      </Form>
    </Modal>
  );
};

export default ProviderModal;

import React, { FC, useEffect } from 'react';
import map from 'lodash/map';

import { config } from 'data';
import { businessAccountService } from 'services';
import { useLang, useModal, useMutation, useQueryInvalidate } from 'hooks';
import { useAllCurrenciesQuery, useBusinessAccountDetailsQuery } from 'hooks/queries';
import { BusinessAccountStatus, CurrenciesModal, VerificationDescriptions } from 'components/layout';
import { Button, Descriptions, Form, Link, Modal, PopconfirmButton } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { BusinessAccountExtended, BusinessAccountStatus as EBusinessAccountStatus, CurrencyCode } from 'types/models';

import ApiModal from './ApiModal';
import FeeModal from './FeeModal';
import NotificationsModal from './NotificationsModal';

type BusinessAccountModalProps = ModalBaseProps & {
  businessAccount: BusinessAccountExtended;
};

const BusinessAccountModal: FC<BusinessAccountModalProps> = ({
  businessAccount,
  open,
  onClose,
}) => {
  const lang = useLang();
  const apiModal = useModal();
  const feeModal = useModal();
  const currenciesModal = useModal();
  const notificationsModal = useModal();
  const queryInvalidate = useQueryInvalidate();

  const businessAccountDetailsQuery = useBusinessAccountDetailsQuery(businessAccount.id);
  const currenciesQuery = useAllCurrenciesQuery({ active: true });

  const invalidateBusinessAccountQueries = async () => {
    await queryInvalidate([config.BUSINESS_ACCOUNTS_QUERY_KEY]);
    await queryInvalidate([config.BUSINESS_ACCOUNT_QUERY_KEY, businessAccount.id]);
  };

  const verifyBusinessAccountMutation = useMutation({
    mutationFn: () => businessAccountService.verifyBusinessAccount(businessAccount.id),
    onSuccess: invalidateBusinessAccountQueries,
    successNotification: lang.get('businessAccount.modal.verifySuccess', { name: businessAccount.email }),
  });

  const unverifyBusinessAccountMutation = useMutation({
    mutationFn: () => businessAccountService.unverifyBusinessAccount(businessAccount.id),
    onSuccess: invalidateBusinessAccountQueries,
    successNotification: lang.get('businessAccount.modal.unverifySuccess', { name: businessAccount.email }),
  });

  const activateBusinessAccountMutation = useMutation({
    mutationFn: () => businessAccountService.activateBusinessAccount(businessAccount.id),
    onSuccess: invalidateBusinessAccountQueries,
    successNotification: lang.get('businessAccount.modal.activateSuccess', { name: businessAccount.name }),
  });

  const deactivateBusinessAccountMutation = useMutation({
    mutationFn: () => businessAccountService.deactivateBusinessAccount(businessAccount.id),
    onSuccess: invalidateBusinessAccountQueries,
    successNotification: lang.get('businessAccount.modal.deactivateSuccess', { name: businessAccount.name }),
  });

  const enableBusinessAccountApiMutation = useMutation({
    mutationFn: () => businessAccountService.enableBusinessAccountApi(businessAccount.id),
    onSuccess: invalidateBusinessAccountQueries,
    successNotification: lang.get('businessAccount.modal.api.enableSuccess', { name: businessAccount.name }),
  });

  const disableBusinessAccountApiMutation = useMutation({
    mutationFn: () => businessAccountService.disableBusinessAccountApi(businessAccount.id),
    onSuccess: invalidateBusinessAccountQueries,
    successNotification: lang.get('businessAccount.modal.api.disableSuccess', { name: businessAccount.name }),
  });

  const updateBusinessAccountDetailsMutation = useMutation({
    mutationFn: (codes: CurrencyCode[]) => businessAccountService.updateBusinessAccountDetails(businessAccount.id, { currencies: codes }),
    onSuccess: () => queryInvalidate([config.BUSINESS_ACCOUNT_DETAILS_QUERY_KEY, businessAccount.id]),
    successNotification: lang.get('businessAccount.modal.currencies.success', { name: businessAccount.name }),
  });

  const handleVerify = async () => {
    await verifyBusinessAccountMutation.mutateAsync();

    onClose();
  };

  const handleUnverify = async () => {
    await unverifyBusinessAccountMutation.mutateAsync();

    onClose();
  };

  const handleActivate = async () => {
    await activateBusinessAccountMutation.mutateAsync();

    onClose();
  };

  const handleDeactivate = async () => {
    await deactivateBusinessAccountMutation.mutateAsync();

    onClose();
  };

  const handleEnableApi = async () => {
    await enableBusinessAccountApiMutation.mutateAsync();

    onClose();
  };

  const handleDisableApi = async () => {
    await disableBusinessAccountApiMutation.mutateAsync();

    onClose();
  };

  useEffect(() => {
    if (!open) {
      apiModal.close();
      feeModal.close();
      currenciesModal.close();
      notificationsModal.close();
    }
  }, [open, apiModal, feeModal, currenciesModal, notificationsModal]);

  return (
    <Modal
      title={lang.get('businessAccount.modal.viewTitle', { name: businessAccount.name })}
      caption={lang.get('businessAccount.modal.viewCaption')}
      cancelText={lang.get('common.actions.close')}
      okButtonProps={{ hidden: true }}
      open={open}
      onCancel={onClose}
    >

      <Form.ActionsItem>

        {businessAccount.verification?.verified ? (
          <PopconfirmButton
            title={lang.get('businessAccount.modal.unverifyTitle')}
            danger
            loading={unverifyBusinessAccountMutation.isPending}
            onConfirm={handleUnverify}
          >
            {lang.get('common.actions.unverify')}
          </PopconfirmButton>
        ) : (
          <PopconfirmButton
            title={lang.get('businessAccount.modal.verifyTitle')}
            type="primary"
            ghost
            loading={verifyBusinessAccountMutation.isPending}
            onConfirm={handleVerify}
          >
            {lang.get('common.actions.verify')}
          </PopconfirmButton>
        )}

        {businessAccount.status === EBusinessAccountStatus.ACTIVE ? (
          <PopconfirmButton
            title={lang.get('businessAccount.modal.deactivateTitle')}
            danger
            loading={deactivateBusinessAccountMutation.isPending}
            onConfirm={handleDeactivate}
          >
            {lang.get('common.actions.deactivate')}
          </PopconfirmButton>
        ) : (
          <PopconfirmButton
            title={lang.get('businessAccount.modal.activateTitle')}
            type="primary"
            ghost
            loading={activateBusinessAccountMutation.isPending}
            onConfirm={handleActivate}
          >
            {lang.get('common.actions.activate')}
          </PopconfirmButton>
        )}

      </Form.ActionsItem>
      <Form.ActionsItem label={lang.choice('businessAccount.modal.currencies.label', businessAccountDetailsQuery.data?.currencies.length ?? 0)}>
        <Button type="default" loading={businessAccountDetailsQuery.isFetching} onClick={currenciesModal.open}>
          {lang.get('businessAccount.modal.currencies.button')}
        </Button>
      </Form.ActionsItem>
      <Form.ActionsItem label={lang.get('businessAccount.modal.fee.label')}>
        <Button type="default" onClick={feeModal.open}>
          {lang.get('common.actions.manage')}
        </Button>
      </Form.ActionsItem>
      <Form.ActionsItem label={lang.get('businessAccount.modal.notifications.label')}>
        <Button type="default" onClick={notificationsModal.open}>
          {lang.get('common.actions.manage')}
        </Button>
      </Form.ActionsItem>
      <Form.ActionsItem label={lang.get('businessAccount.modal.api.label')}>
        {businessAccount.enabledApi ? (
          <>
            <Button type="default" onClick={apiModal.open}>
              {lang.get('common.actions.manage')}
            </Button>
            <PopconfirmButton
              title={lang.get('businessAccount.modal.api.disableTitle')}
              description={lang.get('businessAccount.modal.api.disableCaption')}
              danger
              loading={disableBusinessAccountApiMutation.isPending}
              onConfirm={handleDisableApi}
            >
              {lang.get('common.actions.disable')}
            </PopconfirmButton>
          </>
        ) : (
          <PopconfirmButton
            title={lang.get('businessAccount.modal.api.enableTitle')}
            description={lang.get('businessAccount.modal.api.enableCaption')}
            type="primary"
            ghost
            loading={enableBusinessAccountApiMutation.isPending}
            onConfirm={handleEnableApi}
          >
            {lang.get('common.actions.enable')}
          </PopconfirmButton>
        )}
      </Form.ActionsItem>

      <Form.Divider />

      <Descriptions
        items={[{
          label: lang.get('businessAccount.modal.name.label'),
          children: businessAccount.name,
        }, {
          label: lang.get('common.form.email.label'),
          children: <Link href={`mailto:${businessAccount.email}`}>{businessAccount.email}</Link>,
        }, {
          label: lang.get('businessAccount.modal.type.label'),
          children: lang.get(`businessAccount.types.${businessAccount.type.toLowerCase()}`),
        }, {
          label: lang.get('common.form.status.label'),
          children: <BusinessAccountStatus status={businessAccount.status} />,
        }]}
      />

      <Form.Divider />

      <VerificationDescriptions verification={businessAccount.verification} />

      <ApiModal
        businessAccount={businessAccount}
        open={apiModal.opened}
        onClose={apiModal.close}
      />

      <FeeModal
        businessAccount={businessAccount}
        open={feeModal.opened}
        onClose={feeModal.close}
      />

      <CurrenciesModal
        initialCurrencyCodes={businessAccountDetailsQuery.data?.currencies ?? []}
        currencyCodes={map(currenciesQuery.data, 'code')}
        open={currenciesModal.opened}
        onSubmit={updateBusinessAccountDetailsMutation.mutateAsync}
        onClose={currenciesModal.close}
      />

      <NotificationsModal
        businessAccount={businessAccount}
        open={notificationsModal.opened}
        onClose={notificationsModal.close}
      />

    </Modal>
  );
};

export default BusinessAccountModal;

import React from 'react';

import { config, moment } from 'data';
import { formatter } from 'helpers';
import { useLang, useModal, useTable, useTableQuery } from 'hooks';
import { useCurrencyRatesQuery } from 'hooks/queries';
import { Plus } from 'components/icons';
import { TableView } from 'components/layout';
import { Button, DateRangePicker, Form, Select, Table } from 'components/ui';
import { Nullable } from 'types/common';
import { TableColumns } from 'types/components';
import { CurrencyCode, CurrencyRate } from 'types/models';

import styles from './styles.module.css';

import Modal from './Modal';

type TableParams = {
  currency?: CurrencyCode;
  dates?: Nullable<[Nullable<string>, Nullable<string>]>;
};

const initialTableParams: TableParams = {
  dates: [
    moment().startOf('day').toISOString(),
    moment().endOf('day').toISOString(),
  ],
};

const CurrencyRatesView = () => {
  const lang = useLang();
  const modal = useModal();
  const table = useTable<CurrencyRate, TableParams>([config.CURRENCY_RATES_QUERY_KEY], initialTableParams);

  const currencyRatesQuery = useCurrencyRatesQuery({
    page: table.page,
    currency: table.params.currency || undefined,
    dateFrom: (table.params.dates && table.params.dates[0]) || undefined,
    dateTo: (table.params.dates && table.params.dates[1]) || undefined,
  });

  const handleCreateClick = modal.open;

  useTableQuery(table, currencyRatesQuery);

  const columns: TableColumns<CurrencyRate> = [
    {
      className: styles.table__date,
      key: 'date',
      title: lang.get('currencyRate.list.date'),
      render: (_, currencyRate) => formatter.formatDateTime(currencyRate.date),
    }, {
      className: styles.table__name,
      key: 'name',
      title: lang.get('currencyRate.list.currency'),
      render: (_, currencyRate) => currencyRate.targetCurrency,
    }, {
      className: styles.table__rate,
      key: 'rate',
      title: lang.get('currencyRate.list.rate'),
      render: (_, currencyRate) => formatter.formatNumber(currencyRate.rate, config.CURRENCY_RATE_PRECISION),
    },
  ];

  return (
    <TableView
      title={lang.get('currencyRate.list.title')}
      actions={(
        <Button icon={<Plus />} onClick={handleCreateClick}>
          {lang.get('currencyRate.list.create')}
        </Button>
      )}
    >

      <TableView.Filters<TableParams>
        initialValues={table.params}
        inline
        onSubmit={table.setParams}
      >
        <Form.Item name="currency">
          <Select.Currency
            placeholder={lang.get('currencyRate.filters.currencies')}
            allowClear
            onlyActive
            popupMatchSelectWidth={false}
          />
        </Form.Item>
        <Form.Item name="dates">
          <DateRangePicker maxDate={moment()} allowClear={false} />
        </Form.Item>
      </TableView.Filters>

      <Table<CurrencyRate>
        columns={columns}
        dataSource={table.data}
        pagination={table.pagination}
        rowKey={(currencyRate) => currencyRate.id}
        loading={currencyRatesQuery.isFetching}
        onChange={table.onChange}
      />

      <Modal
        open={modal.opened}
        onClose={modal.close}
      />

    </TableView>
  );
};

export default CurrencyRatesView;

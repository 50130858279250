import React, { useState } from 'react';

import { config, moment } from 'data';
import { formatter } from 'helpers';
import { useLang, useModal, useTable, useTableQuery } from 'hooks';
import { useAllUsersQuery, useAuditLogsQuery } from 'hooks/queries';
import { DashboardLayout } from 'layouts';
import { TableView, UserInfo } from 'components/layout';
import { DateRangePicker, Form, Select, Table } from 'components/ui';
import { Nullable, Uuid } from 'types/common';
import { TableColumns } from 'types/components';
import { AuditLog, AuditLogAction, AuditLogObject } from 'types/models';

import styles from './styles.module.css';

import Modal from './Modal';

type TableParams = {
  search?: string;
  userId?: Uuid;
  action?: AuditLogAction;
  object?: AuditLogObject;
  dates?: Nullable<[Nullable<string>, Nullable<string>]>;
};

const initialTableParams: TableParams = {
  //
};

const AuditLogsPage = () => {
  const lang = useLang();
  const modal = useModal();
  const table = useTable<AuditLog, TableParams>([config.AUDIT_LOGS_QUERY_KEY], initialTableParams);

  const [log, setLog] = useState<AuditLog>();

  const usersQuery = useAllUsersQuery();

  const logsQuery = useAuditLogsQuery({
    page: table.page,
    search: table.params.search || undefined,
    userId: table.params.userId || undefined,
    action: table.params.action || undefined,
    objectType: table.params.object || undefined,
    dateFrom: (table.params.dates && table.params.dates[0]) || undefined,
    dateTo: (table.params.dates && table.params.dates[1]) || undefined,
  });

  const handleViewClick = (log: AuditLog) => () => {
    setLog(log);

    modal.open();
  };

  useTableQuery(table, logsQuery);

  const columns: TableColumns<AuditLog> = [
    {
      className: styles.table__date,
      key: 'date',
      title: lang.get('auditLog.list.date'),
      render: (_, log) => formatter.formatDateTime(log.createdAt),
    }, {
      key: 'user',
      title: lang.get('auditLog.list.user'),
      render: (_, log) => (
        <UserInfo
          name={formatter.formatName(log.creator.firstName, log.creator.lastName)}
          email={log.creator.email}
        />
      ),
    }, {
      className: styles.table__action,
      key: 'action',
      title: lang.get('auditLog.list.action'),
      render: (_, log) => lang.get(`auditLog.actions.${log.action.toLowerCase()}`),
    }, {
      key: 'object',
      title: lang.get('auditLog.list.object'),
      render: (_, log) => lang.get(`auditLog.objects.${log.objectType.toLowerCase()}`),
    },
  ];

  return (
    <DashboardLayout title={lang.get('auditLog.list.title')}>
      <TableView title={lang.get('auditLog.list.title')}>

        <TableView.Filters<TableParams>
          initialValues={table.params}
          withSearch
          onSubmit={table.setParams}
        >
          <Form.Item name="userId" label={lang.get('auditLog.filters.user')}>
            <Select
              placeholder={lang.get('common.actions.all')}
              options={usersQuery.data?.map((user) => ({
                value: user.id,
                label: (
                  <UserInfo
                    className={styles.user__option}
                    nameClassName={styles.user__option__name}
                    emailClassName={styles.user__option__email}
                    name={formatter.formatName(user.firstName, user.lastName)}
                    email={user.email}
                  />
                ),
                title: [user.firstName, user.lastName, user.email].join(' '),
              }))}
              allowClear
              loading={usersQuery.isFetching}
              showSearch
            />
          </Form.Item>
          <Form.Item name="action" label={lang.get('auditLog.filters.action')}>
            <Select
              placeholder={lang.get('common.actions.all')}
              options={Object.values(AuditLogAction).map((action) => ({
                value: action,
                label: lang.get(`auditLog.actions.${action.toLowerCase()}`),
              }))}
              allowClear
            />
          </Form.Item>
          <Form.Item name="object" label={lang.get('auditLog.filters.object')}>
            <Select
              placeholder={lang.get('common.actions.all')}
              options={Object.values(AuditLogObject).map((object) => ({
                value: object,
                label: lang.get(`auditLog.objects.${object.toLowerCase()}`),
              }))}
              allowClear
            />
          </Form.Item>
          <Form.Item name="dates" label={lang.get('auditLog.filters.date')}>
            <DateRangePicker maxDate={moment()} allowEmpty />
          </Form.Item>
        </TableView.Filters>

        <Table<AuditLog>
          columns={columns}
          dataSource={table.data}
          pagination={table.pagination}
          rowKey={(log) => log.id}
          loading={logsQuery.isFetching}
          clickable
          onRow={(log) => ({ onClick: handleViewClick(log) })}
          onChange={table.onChange}
        />
      </TableView>

      {log && (
        <Modal
          log={log}
          open={modal.opened}
          onClose={modal.close}
        />
      )}

    </DashboardLayout>
  );
};

export default AuditLogsPage;

import { Nullable, Uuid } from './common';

export enum ActivityStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum AuditLogAction {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}

export enum AuditLogObject {
  CLIENT = 'client',
  COST = 'cost',
  CURRENCY = 'currency',
  CURRENCY_RATE = 'currency-rate',
  FEE_COMMISSION = 'fee-commission',
  FEE_GROUP = 'fee-group',
  PROVIDER = 'provider',
}

export enum BundleStatus {
  NEW = 'NEW',
  APPROVED = 'APPROVED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED',
  FAILED = 'FAILED',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
}

export enum BusinessAccountApiStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum BusinessAccountRoleId {
  'OWNER' = 'ROLE_OWNER',
  'ACCOUNTANT' = 'ROLE_ACCOUNTANT',
  'ANALYST' = 'ROLE_ANALYST',
}

export enum BusinessAccountStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum BusinessAccountType {
  COMPANY = 'COMPANY',
  INDIVIDUAL = 'INDIVIDUAL',
}

export enum BusinessApplicationStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  ARCHIVED = 'ARCHIVED',
}

export enum ClientStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum FeeCommissionLevel {
  GLOBAL = 'GLOBAL',
  GROUP = 'GROUP',
  CLIENT = 'CLIENT',
}

export enum ProviderFrequency {
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  ANNUALLY = 'annually',
}

export enum TransactionDirection {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING',
}

export enum TransactionFeeType {
  FIXED = 'FIXED',
  PERCENT = 'PERCENT',
}

export enum TransactionStatus {
  COMPLETED = 'completed',
  PENDING = 'pending',
  CANCELED = 'canceled',
  FAILED = 'failed',
  INSUFFICIENT_FUNDS = 'insufficient-funds',
}

export enum TransactionType {
  ADJUSTMENT = 'ADJUSTMENT',
  EXTERNAL_CREDIT = 'EXTERNAL_CREDIT',
  EXTERNAL = 'EXTERNAL',
  FASTPAY = 'FASTPAY',
  EXCHANGE = 'EXCHANGE',
  P2P = 'P2P',
  PAYNOW = 'PAYNOW',
  TT = 'TT',
  VA_OPEN = 'VA_OPEN',
  MONTHLY_SERVICE_FEE = 'MONTHLY_SERVICE_FEE',
}

export enum UserRoleId {
  ADMIN = 'ROLE_SUPER_ADMIN',
  ANALYST = 'ROLE_ANALYST_ADMIN',
  VIEWER = 'ROLE_VIEWER_ADMIN',
}

type Model<Params> = Params & {
  id: Uuid;
  createdAt: string;
  updatedAt: Nullable<string>;
};

type Role<Id> = {
  id: Id;
  name: string;
  description: Nullable<string>;
};

export type AuditLog = {
  id: Uuid;
  objectId: Nullable<Uuid>;
  objectType: AuditLogObject;
  action: AuditLogAction;
  oldValue: Nullable<object>;
  newValue: Nullable<object>;
  createdAt: string;
  creator: {
    id: Uuid;
    firstName: Nullable<string>;
    lastName: Nullable<string>;
    email: string;
  };
};

export type Bundle = Model<{
  externalId: string;
  status: BundleStatus;
  client: BusinessAccountBasic;
}>;

export type BundleExtended = Bundle & {
  errorText: string;
  file: UploadedFile;
};

export type BusinessAccount = {
  id: Uuid;
  name: string;
  type: BusinessAccountType;
  status: BusinessAccountStatus;
  enabledApi: boolean;
  balance?: BusinessAccountBalance;
};

export type BusinessAccountBasic = Pick<BusinessAccount, 'id' | 'name' | 'type'>;

export type BusinessAccountExtended = BusinessAccount & {
  email: string;
  verification: Nullable<Verification>;
};

export type BusinessAccountBalance = {
  id: Uuid;
  totalBalance: number;
  totalIncomingPendingBalance: number;
  totalOutgoingPendingBalance: number;
  currency: CurrencyCode;
  details: Array<{
    virtualAccountId: Uuid;
    externalId: string;
    title: string;
    balances: Array<{
      balance: number;
      incomingPendingBalance: number;
      outgoingPendingBalance: number;
      currency: CurrencyCode;
    }>;
  }>;
};

export type BusinessAccountDetails = {
  id: Uuid;
  currencies: CurrencyCode[];
  feeCommissions: Nullable<FeeCommissionSimple[]>;
  feeGroupId?: Nullable<Uuid>;
  lowBalanceLevel: Nullable<number>;
  webhookUrl: Nullable<string>;
};

export type BusinessAccountMember = Client & {
  roles: BusinessAccountRole[];
};

export type BusinessAccountRole = Role<BusinessAccountRoleId>;

export type BusinessAccountVirtualAccount = {
  id: Uuid;
  externalId: string;
  title: string;
  balances: Array<{
    balance: number;
    outgoingPendingBalance: number;
    incomingPendingBalance: number;
    currency: Pick<Currency, 'code' | 'name'>;
  }>;
};

export type BusinessApplication = Model<{
  name: string;
  email: string;
  activity: string;
  mainIndustry?: Nullable<BusinessApplicationIndustry>;
  rejectReason: Nullable<string>;
  status: BusinessApplicationStatus;
}>;

export type BusinessApplicationDetails = {
  averageAccountBalance: Nullable<number>;
  fallBelowFee: Nullable<string>;
  annualMaintenanceFees: Nullable<string>;
  accountOpeningFees: Nullable<string>;
  notes: Nullable<string>;
};

export type BusinessApplicationIndustry = {
  id: Uuid;
  value: string;
};

export type Client = {
  id: Uuid;
  firstName: Nullable<string>;
  lastName: Nullable<string>;
  email: string;
  phone: Nullable<string>;
  residenceCountryCode: Nullable<string>;
  status: ClientStatus;
  verification: Nullable<Verification>;
};

export type Cost = {
  id: Uuid;
  country: string;
  currency: CurrencyCode;
  transactionFeeType: TransactionFeeType;
  transactionFee: number;
  fxMarkup: number;
  minTransactionFeeAmount: number;
  maxTransactionFeeAmount: number;
  startDate?: Nullable<string>;
  endDate?: Nullable<string>;
  notes: Nullable<string>;
  active: boolean;
  c2c: boolean;
  c2b: boolean;
  b2c: boolean;
  b2b: boolean;
  provider: {
    id: Uuid;
    name: string;
    currency: CurrencyCode;
  };
};

export type Country = {
  code: CountryCode;
  name: string;
};

export type CountryCode = string;

export type Currency = {
  code: CurrencyCode;
  name: string;
  active: boolean;
};

export type CurrencyCode = string;

export type CurrencyRate = {
  id: Uuid;
  baseCurrency: CurrencyCode;
  targetCurrency: CurrencyCode;
  rate: number;
  date: string;
};

export type FeeCommission = Model<{
  feeGroupId?: Nullable<Uuid>;
  name: string;
  level: FeeCommissionLevel;
  transactionType: TransactionType;
  direction: Nullable<TransactionDirection>;
  transactionFeeType: TransactionFeeType;
  transactionFee: number;
  fxMarkup: number;
  minTransactionFeeAmount: number;
  maxTransactionFeeAmount: number;
  currency: CurrencyCode;
  startDate: Nullable<string>;
  endDate: Nullable<string>;
  description: Nullable<string>;
  active: boolean;
}>;

export type FeeCommissionSimple = {
  id: Uuid;
  name: string;
};

export type FeeGroup = Model<{
  name: string;
  description: string;
  assigned: boolean;
}>;

export type Provider = {
  id: Uuid;
  name: string;
  currency: CurrencyCode;
  frequency?: Nullable<ProviderFrequency>;
  minFeeAmount: number;
  notes?: Nullable<string>;
  active: boolean;
  files: UploadedFile[];
};

export type ProviderBasic = {
  id: Uuid;
  name: string;
  active: boolean;
};

export type ProviderSimple = Model<{
  name: string;
  currency: CurrencyCode;
  active: boolean;
  balance: Nullable<{
    available: number;
    pending: Nullable<number>;
    total: Nullable<number>;
    currency: CurrencyCode;
  }>;
}>;

export type ReportDailyBalanceItem = {
  startingBalance: number;
  endingBalance: number;
  totalAmount: number;
  totalProviderFees: number;
  totalTransactionFees: number;
  currency: CurrencyCode;
  date: string;
};

export type ReportDailyRemittanceItem = {
  bundles: number;
  acceptedTransactions: number;
  rejectedTransactions: number;
  remittedAmount: number;
  rejectedAmount: number;
  remittedFxFees: number;
  remittedTransactionFees: number;
  currency: CurrencyCode;
  date: string;
};

export type ReportDailyTransactionItem = {
  totalTransactions: number;
  totalAmount: number;
  totalProviderFees: number;
  totalTransactionFees: number;
  currency: CurrencyCode;
  date: string;
};

export type ReportProvidersDailyBalanceItem = {
  provider: string;
  available: number;
  pending: Nullable<number>;
  total: Nullable<number>;
  currency: CurrencyCode;
  date: string;
};

export type Topic = {
  id: Uuid;
  title: string;
};

export type Transaction = Model<{
  userId: Uuid;
  clientId: Uuid;
  flowId?: Nullable<Uuid>;
  externalId: Nullable<string>;
  providerReferenceId: Nullable<string>;
  clientReferenceId: Nullable<string>;
  transactionType: TransactionType;
  beneficiary?: Nullable<string>;
  originAmount: number;
  originCurrency: CurrencyCode;
  destinationAmount: number;
  destinationCurrency: CurrencyCode;
  balance: number;
  previousBalance: number;
  currencyRate: number;
  effectiveRate: number;
  transactionFee: number;
  fxMarkupFee: number;
  providerFee: number;
  status: TransactionStatus;
  errorMsg?: Nullable<string>;
  notes?: Nullable<string>;
  statusChangedAt: Nullable<string>;
  transferDetail?: object;
  client: BusinessAccountBasic;
  provider: Nullable<{
    id: Uuid;
    name: string;
  }>;
  virtualAccount: Nullable<{
    id: Uuid;
    externalId: string;
    title: string;
  }>;
}>;

export type UploadedFile = Model<{
  fileName: string;
  originFileName: string;
  bucketUrl: string;
}>;

export type User = {
  id: Uuid;
  firstName: Nullable<string>;
  lastName: Nullable<string>;
  email: string;
  phone: Nullable<string>;
  roles: UserRole[];
};

export type UserRole = Role<UserRoleId>;

export type Verification = {
  detailsLink: Nullable<string>;
  verified: boolean;
  createdAt: string;
  updatedAt: string;
  updatedBy: Nullable<{
    id: Uuid;
    name: string;
    email: string;
  }>;
};

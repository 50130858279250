import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { url } from 'helpers';
import { useLang, useModal } from 'hooks';
import { Chart, ChevronDown, PieChart, TopUp, User } from 'components/icons';
import { BusinessAccountBalance, BusinessAccountIcon, CardView, TransactionModal } from 'components/layout';
import { Button, Dropdown, Flex } from 'components/ui';
import { BusinessAccountExtended } from 'types/models';

import Modal from './Modal';

type BusinessAccountViewProps = {
  businessAccount: BusinessAccountExtended;
};

const BusinessAccountView: FC<BusinessAccountViewProps> = ({ businessAccount }) => {
  const lang = useLang();
  const navigate = useNavigate();
  const modal = useModal();
  const transactionModal = useModal();

  return (
    <>

      <CardView
        icon={<BusinessAccountIcon businessAccountType={businessAccount.type} size="large" />}
        title={lang.get('businessAccount.single.title', { name: businessAccount.name })}
        caption={<BusinessAccountBalance businessAccount={businessAccount} />}
        actions={(
          <Flex gap="small" wrap="wrap">
            <Button type="default" icon={<User />} onClick={modal.open}>
              {lang.get('businessAccount.single.profile')}
            </Button>
            <Button
              type="default"
              icon={<Chart />}
              onClick={() => navigate(url.toBusinessAccountTransactions(businessAccount.id))}
            >
              {lang.get('businessAccount.single.transactions')}
            </Button>
            <Dropdown
              menu={{
                items: [{
                  key: 'dailyBalance',
                  label: lang.get('common.menu.dailyBalance'),
                  onClick: () => navigate(url.toBusinessAccountDailyBalance(businessAccount.id)),
                }, {
                  key: 'dailyRemittance',
                  label: lang.get('common.menu.dailyRemittance'),
                  onClick: () => navigate(url.toBusinessAccountDailyRemittance(businessAccount.id)),
                }, {
                  key: 'dailyTransactions',
                  label: lang.get('common.menu.dailyTransactions'),
                  onClick: () => navigate(url.toBusinessAccountDailyTransactions(businessAccount.id)),
                }],
              }}
            >
              <Button type="default" icon={<PieChart />}>
                {lang.get('businessAccount.single.reports')}
                <ChevronDown />
              </Button>
            </Dropdown>
            <Button type="default" icon={<TopUp />} onClick={transactionModal.open}>
              {lang.get('businessAccount.single.adjustBalance')}
            </Button>
          </Flex>
        )}
      />

      <Modal
        businessAccount={businessAccount}
        open={modal.opened}
        onClose={modal.close}
      />

      <TransactionModal
        businessAccount={businessAccount}
        open={transactionModal.opened}
        onClose={transactionModal.close}
      />

    </>
  );
};

export default BusinessAccountView;

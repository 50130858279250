import React, { useState } from 'react';

import { config } from 'data';
import { formatter } from 'helpers';
import { useLang, useModal, useTable, useTableQuery } from 'hooks';
import { useBusinessApplicationsQuery } from 'hooks/queries';
import { DashboardLayout } from 'layouts';
import { Plus } from 'components/icons';
import { BusinessApplicationStatus, TableView } from 'components/layout';
import { Button, Form, Select, Table } from 'components/ui';
import { TableColumns } from 'types/components';
import { BusinessApplication, BusinessApplicationStatus as EBusinessApplicationStatus } from 'types/models';
import { BusinessApplicationsParams } from 'types/services';

import styles from './styles.module.css';

import Modal from './Modal';

type TableParams = {
  search?: string;
  status?: EBusinessApplicationStatus;
};

const initialTableParams: TableParams = {
  //
};

const BusinessApplicationsPage = () => {
  const lang = useLang();
  const modal = useModal();
  const table = useTable<BusinessApplication, TableParams>([config.BUSINESS_APPLICATIONS_QUERY_KEY], initialTableParams);

  const [businessApplication, setBusinessApplication] = useState<BusinessApplication>();

  const businessApplicationsParams: BusinessApplicationsParams = {
    page: table.page,
    search: table.params.search || undefined,
    status: table.params.status || undefined,
  };

  const businessApplicationsQuery = useBusinessApplicationsQuery(businessApplicationsParams);

  const handleCreateClick = () => {
    setBusinessApplication(undefined);

    modal.open();
  };

  const handleViewClick = (businessApplication: BusinessApplication) => () => {
    setBusinessApplication(businessApplication);

    modal.open();
  };

  useTableQuery(table, businessApplicationsQuery);

  const tableColumns: TableColumns<BusinessApplication> = [
    {
      className: styles.table__date,
      key: 'date',
      title: lang.get('businessApplication.list.date'),
      render: (_, businessApplication) => formatter.formatDateTime(businessApplication.updatedAt ?? businessApplication.createdAt),
    }, {
      className: styles.table__name,
      key: 'name',
      title: lang.get('businessApplication.list.name'),
      render: (_, businessApplication) => <Table.Truncate width="middle">{businessApplication.name}</Table.Truncate>,
    }, {
      key: 'status',
      title: lang.get('businessApplication.list.status'),
      render: (_, businessApplication) => <BusinessApplicationStatus status={businessApplication.status} />,
    },
  ];

  return (
    <DashboardLayout title={lang.get('businessApplication.list.title')}>
      <TableView
        title={lang.get('businessApplication.list.title')}
        actions={(
          <Button icon={<Plus />} onClick={handleCreateClick}>
            {lang.get('businessApplication.list.create')}
          </Button>
        )}
      >

        <TableView.Filters<TableParams>
          initialValues={table.params}
          withSearch
          inline
          onSubmit={table.setParams}
        >
          <Form.Item name="status">
            <Select
              placeholder={lang.get('businessApplication.filters.statuses')}
              options={Object.values(EBusinessApplicationStatus).map((status) => ({
                value: status,
                label: lang.get(`businessApplication.statuses.${status.toLowerCase()}`),
              }))}
              allowClear
              popupMatchSelectWidth={false}
            />
          </Form.Item>
        </TableView.Filters>

        <Table<BusinessApplication>
          columns={tableColumns}
          dataSource={table.data}
          pagination={table.pagination}
          rowKey={(businessApplication) => businessApplication.id}
          loading={businessApplicationsQuery.isFetching}
          clickable
          onRow={(businessApplication) => ({ onClick: handleViewClick(businessApplication) })}
          onChange={table.onChange}
        />

        <Modal
          businessApplication={businessApplication}
          open={modal.opened}
          onClose={modal.close}
        />

      </TableView>
    </DashboardLayout>
  );
};

export default BusinessApplicationsPage;

import React, { FC } from 'react';

import { formatter } from 'helpers';
import { useAllBusinessAccountVirtualAccountsQuery } from 'hooks/queries';
import { Flex, Select, Text } from 'components/ui';
import { Uuid } from 'types/common';
import { SelectProps } from 'types/components';

import styles from './styles.module.css';

type VirtualAccountSelectProps = Omit<SelectProps, 'options' | 'loading'> & {
  businessAccountId?: Uuid;
};

const VirtualAccountSelect: FC<VirtualAccountSelectProps> = ({ businessAccountId, ...props }) => {
  const virtualAccountsQuery = useAllBusinessAccountVirtualAccountsQuery(businessAccountId);

  return (
    <Select
      options={virtualAccountsQuery.data?.map((account) => ({
        value: account.id,
        label: (
          <Flex className={styles.account__option} vertical>
            <Text className={styles.account__option__title}>{account.title} / {account.externalId}</Text>
            <Text className={styles.account__option__balance} type="secondary">
              {account.balances
                .map((balance) => formatter.formatCurrency(balance.balance, balance.currency.code))
                .join(' • ')}
            </Text>
          </Flex>
        ),
      }))}
      loading={virtualAccountsQuery.isFetching}
      {...props}
    />
  );
};

export default VirtualAccountSelect;

import React from 'react';
import { Form as BaseForm } from 'antd';
import { FieldData } from 'rc-field-form/es/interface';
import { ValidationError } from 'yup';

import { Nullable } from 'types/common';
import { FormProps } from 'types/components';

import ActionsItem from './ActionsItem';
import CheckboxItem from './CheckboxItem';
import Columns from './Columns';
import Divider from './Divider';
import Item from './Item';
import SwitchItem from './SwitchItem';
import Title from './Title';
import UploadItem from './UploadItem';

const Form = <Values extends object>({
  form,
  validationSchema,
  layout = 'vertical',
  preserve = false,
  onValuesChange,
  onFinish,
  ...props
}: FormProps<Values>) => {
  const validateValues = async (values: Values): Promise<Nullable<Values>> => {
    if (!form || !validationSchema) {
      return values;
    }

    form.setFields(Object.keys(values).map<FieldData>((field) => ({
      name: field,
      errors: undefined,
    })));

    try {
      return await validationSchema.validate(values, { abortEarly: false });
    } catch (err) {
      const error = err as ValidationError;
      const errors: Record<string, FieldData> = {};

      error.inner.forEach((error) => {
        const field = error.path as string;

        const name = field
          .replace(/\[/g, '.')
          .replace(/]/g, '')
          .split('.')
          .map((part) => part.match(/^\d+$/) ? Number(part) : part);

        // Generate an error for each part of the field name (in case of an array)
        // (e.g. ['user', 0, 'name'] -> 'user', 'user.0', 'user.0.name')
        const path: Array<string | number> = [];

        name.forEach((part) => {
          path.push(part);

          const key = path.join('.');

          if (!errors[key]) {
            errors[key] = {
              name: key,
              errors: error.errors,
            };
          }
        });
      });

      form.setFields(Object.values(errors));
    }

    return null;
  };

  const handleValuesChange = async (changedValues: Partial<Values>, values: Values) => {
    await validateValues(values);

    onValuesChange?.(changedValues, values);
  };

  const handleFinish = async (values: Values) => {
    const validatedValues = await validateValues(values);

    if (!validatedValues) {
      return;
    }

    if (!onFinish) {
      return;
    }

    const result = await onFinish(validatedValues);

    if (result !== false) {
      form?.resetFields();
    }
  };

  return (
    <BaseForm
      form={form}
      layout={layout}
      preserve={preserve}
      onValuesChange={handleValuesChange}
      onFinish={handleFinish}
      {...props}
    />
  );
};

export default Object.assign(Form, {
  ActionsItem,
  CheckboxItem,
  Columns,
  Divider,
  Item,
  SwitchItem,
  Title,
  UploadItem,
});

import React, { useMemo } from 'react';
import map from 'lodash/map';

import { config } from 'data';
import { currencyService } from 'services';
import { useLang, useModal, useMutation, useQueryInvalidate } from 'hooks';
import { useAllCurrenciesQuery } from 'hooks/queries';
import { CardView, CurrenciesModal } from 'components/layout';
import { Button } from 'components/ui';

const CurrenciesView = () => {
  const lang = useLang();
  const modal = useModal();
  const queryInvalidate = useQueryInvalidate();

  const currenciesQuery = useAllCurrenciesQuery();

  const invalidateCurrencyQueries = async () => {
    await queryInvalidate([config.CURRENCIES_QUERY_KEY]);
    await queryInvalidate([config.CURRENCY_RATES_QUERY_KEY]);
  };

  const updateCurrenciesMutation = useMutation({
    mutationFn: currencyService.updateCurrencies,
    onSuccess: invalidateCurrencyQueries,
    successNotification: lang.get('currency.modal.success'),
  });

  const currencies = useMemo(() => currenciesQuery.data ?? [], [currenciesQuery.data]);
  const activeCurrencyCodes = useMemo(() => map(currencies.filter((currency) => currency.active), 'code'), [currencies]);

  return (
    <>

      <CardView
        title={lang.get('currency.list.title')}
        caption={lang.choice('currency.list.caption', activeCurrencyCodes.length)}
        actions={(
          <Button
            color="primary"
            variant="filled"
            loading={currenciesQuery.isFetching}
            onClick={modal.open}
          >
            {lang.get('common.actions.edit')}
          </Button>
        )}
      />

      <CurrenciesModal
        initialCurrencyCodes={activeCurrencyCodes}
        currencyCodes={map(currencies, 'code')}
        open={modal.opened}
        onSubmit={updateCurrenciesMutation.mutateAsync}
        onClose={modal.close}
      />

    </>
  );
};

export default CurrenciesView;

import { IParseOptions, IStringifyOptions, parse, stringify } from 'qs';

import { Uuid } from 'types/common';

const encodeParams = (params?: object, options?: IStringifyOptions) => stringify(params, {
  ...options,
  arrayFormat: 'brackets',
  allowDots: true,
  encodeValuesOnly: true,
});

const decodeParams = (params: string, options?: IParseOptions) => parse(params, {
  ...options,
  allowDots: true,
});

const prepareParams = (params?: object) => encodeParams(params, { addQueryPrefix: true });

const toAuditLogs = () => toSettings('/audit-logs');

const toAuth = (hash: Uuid) => `/auth/${hash}`;

const toBundles = () => toTransactions('/bundles');

const toBusinessAccounts = (path = '') => toDashboard(`/business-accounts${path}`);

const toBusinessAccount = (id: Uuid, path = '') => toBusinessAccounts(`/${id}${path}`);

const toBusinessAccountDailyBalance = (id: Uuid) => toBusinessAccountReports(id, '/daily-balance');

const toBusinessAccountDailyRemittance = (id: Uuid) => toBusinessAccountReports(id, '/daily-remittance');

const toBusinessAccountDailyTransactions = (id: Uuid) => toBusinessAccountReports(id, '/daily-transactions');

const toBusinessAccountReports = (id: Uuid, path = '') => toBusinessAccount(id, `/reports${path}`);

const toBusinessAccountTransactions = (id: Uuid) => toBusinessAccount(id, '/transactions');

const toBusinessApplications = () => toDashboard('/business-applications');

const toClients = () => toDashboard('/individuals');

const toCostManagement = () => toSettings('/cost-management');

const toCurrencies = () => toSettings('/currencies');

const toDailyRemittance = () => toReports('/daily-remittance');

const toDashboard = (path = '') => `/dashboard${path}`;

const toFeeManagement = () => toSettings('/fee-management');

const toLogin = () => '/';

const toPasswordChange = (hash: Uuid) => `/password-change/${hash}`;

const toPasswordReset = () => '/password-reset';

const toProvidersDailyBalance = () => toReports('/providers-daily-balance');

const toReports = (path = '') => toDashboard(`/reports${path}`);

const toSettings = (path = '') => toDashboard(`/settings${path}`);

const toTransactions = (path = '', params?: object) => toDashboard(`/transactions${path}${prepareParams(params)}`);

const toUsers = () => toSettings('/users');

const url = {
  encodeParams,
  decodeParams,
  toAuditLogs,
  toAuth,
  toBundles,
  toBusinessAccounts,
  toBusinessAccount,
  toBusinessAccountDailyBalance,
  toBusinessAccountDailyRemittance,
  toBusinessAccountDailyTransactions,
  toBusinessAccountReports,
  toBusinessAccountTransactions,
  toBusinessApplications,
  toClients,
  toCostManagement,
  toCurrencies,
  toDailyRemittance,
  toDashboard,
  toFeeManagement,
  toLogin,
  toPasswordChange,
  toPasswordReset,
  toProvidersDailyBalance,
  toReports,
  toSettings,
  toTransactions,
  toUsers,
};

export default url;
